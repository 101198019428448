import buildObservations from 'components/templates/PDF/shared/Observation'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { borderLayoutDefault } = utilsPDF

export function buildTableFooter() {
  return {
    table: {
      body: [
        ...buildObservations({
          stack: [
            {
              text: 'É considerado APROVADO(A) o(a) aluno(a) que obtiver a média final, igual ou superior a 6 (seis) pontos em cada disciplina e frequência igual ou superior a 75% (setenta e cinco) do total da carga horária. As notas precedidas de asterisco (*) estão abaixo do valor mínimo exigido para aprovação.'
            }
          ]
        })
      ],
      widths: [240, 60, '*']
    },
    layout: {
      ...borderLayoutDefault,
      paddingBottom: () => 10,
      paddingLeft: () => 10,
      paddingRight: () => 10,
      paddingTop: () => 10
    }
  }
}
