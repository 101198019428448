import { styled, Typography } from '@mui/material'

const Header = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.28rem'
  }
}))
const Separator = styled('div')(({ theme }) => ({
  color: '#FFF',
  fontFamily: 'Roboto',
  fontWeight: '400',
  margin: '0px 5px 0px 5px',
  padding: '0px 0px 35px 0px',
  fontSize: '1.7rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '2.28rem'
  }
}))

const Footer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  fontWeight: 700,
  margin: '0 0 10px 0',
  padding: '4px 10px',
  fontSize: '0.85rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.1rem'
  }
}))

const Message = styled(Typography)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#FFF',
  fontWeight: 700,
  margin: '0 0 10px 0',
  padding: '4px 10px',
  fontSize: '0.85rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.42rem'
  }
}))

export { Header, Footer, Separator, Message }
