import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getSelectProfile,
  getSelectSector,
  newUserAccess
} from 'services/api/admin/system/AccessUsers'
import { ERROR, SUCCESS } from 'services/constants'
import { getErrorMessage, updateErrorMessage } from 'services/helpers'
import { MESSAGE } from './constants'

const selectProfile = async ({ setListProfile, setSnack }) => {
  setSnack('')

  const { data, error, status } = await getSelectProfile()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: ''
    })
  }
  const profile = data

  return setListProfile(profile)
}

const selectSector = async ({ setListSector, setSnack }) => {
  setSnack('')

  const { data, error, status } = await getSelectSector()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: ''
    })
  }

  const sectors = data.map((sector) => ({
    value: sector.label,
    label: sector.label
  }))

  console.log(sectors)

  return setListSector(sectors)
}

const createAcces = async ({ setSnack, values, navigate }) => {
  setSnack('')

  const payload = {
    ...values
  }

  const { data, error, status } = await newUserAccess(payload)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListAccess
    })
  }

  if (error) {
    const message = getErrorMessage(error, status)
    return setSnack(message, ERROR)
  }

  setSnack(MESSAGE.SUCCESS, SUCCESS)

  return setTimeout(() => navigate(-1), 3000)
}

export { selectSector, selectProfile, createAcces }
