const FEEDBACK_DOCUMENT_TYPES = {
  noAccessPermissionToListDocumentTypes: 'para listar os Tipos de Documentos',
  noAccessPermissionToInactiveDocumentTypes: 'para inativar Tipos de Documentos'
}

const FEEDBACK_ACCESS_USERS = {
  noAccessPermissionToListAccess: 'para listar os Acessos'
}

export const FEEDBACK_SYSTEM = {
  ...FEEDBACK_DOCUMENT_TYPES,
  ...FEEDBACK_ACCESS_USERS
}
