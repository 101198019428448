// import getEnvAccess from './getEnvAccess'

function getSystemPermissions(permissions) {
  // const hasPermission = getEnvAccess()
  const permissionsSystem = permissions?.system || {}
  const permissionsDocumentTypes = permissionsSystem?.document_type
  const permissionsSectors = permissionsSystem?.sector
  const permissionsContractsTypes = permissionsSystem?.contract?.contract_type
  const permissionLogs = permissionsSystem?.log
  const permissionUnifyEnrollment = permissionsSystem?.unify_enrollment
  const permissionImports = permissionsSystem?.imports

  const SYSTEM_PERMISSIONS = {
    SYSTEM: Boolean(permissionsSystem),
    SYSTEM_DOCUMENT_TYPES: Boolean(permissionsDocumentTypes?.GET),
    SYSTEM_SECTORS_LIST: Boolean(permissionsSectors?.GET),
    SYSTEM_SECTORS_CREATE: Boolean(permissionsSectors?.POST),
    SYSTEM_DECLARETIONS_LIST: Boolean(permissionsSectors?.GET),
    SYSTEM_DECLARATIONS_CREATE: Boolean(permissionsSectors?.POST),
    SYSTEM_DECLARATIONS_EDIT: Boolean(permissionsSectors?.PUT),
    SYSTEM_CONTRACTS_CREATE: Boolean(permissionsContractsTypes?.POST),
    SYSTEM_CONTRACTS_LIST: Boolean(permissionsContractsTypes?.GET),
    SYSTEM_CONTRACTS_EDIT: Boolean(permissionsContractsTypes?.PUT),
    SYSTEM_SECTORS_EDIT: Boolean(permissionsSectors?.PUT),
    SYSTEM_LOGS_USERS: Boolean(permissionLogs?.GET),
    SYSTEM_UNIFY_ENROLLMENT_LIST: Boolean(permissionUnifyEnrollment?.GET),
    SYSTEM_UNIFY_ENROLLMENT_UPDATE: Boolean(permissionUnifyEnrollment?.PUT),
    SYSTEM_ACCESS_CREATE: Boolean(permissionsContractsTypes?.POST),
    SYSTEM_IMPORTS: Boolean(permissionImports?.POST)
  }

  return SYSTEM_PERMISSIONS
}

export default getSystemPermissions
