import { utilsPDF } from 'components/templates/PDF/shared/utils'
import buildTableFooter from './Footer'
import buildTableHeader from './Header'
import buildTableBody from './Body'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

const handleStudents = ({ students }) =>
  students.map((studentElement) => {
    const student = {
      ...studentElement,
      gradeDetails: studentElement.gradeDetails.map((gradeDetail) =>
        gradeDetail.map((gradeDetailsChunk) => ({
          ...gradeDetailsChunk,
          disciplineHasAlreadyStarted:
            new Date(gradeDetailsChunk.discipline.beginAt) <= new Date()
        }))
      )
    }
    return student
  })

export default function buildTable({
  subjects,
  students,
  classInfo,
  indexChunk
}) {
  const studentsHandled = handleStudents({
    students
  })
  const { minimumGrade } = classInfo
  return [
    {
      table: {
        body: [
          ...buildTableHeader(subjects),
          ...buildTableBody(studentsHandled, indexChunk, minimumGrade)
        ],
        widths: ['auto', 160, ...subjects.map((_) => ['*', '*']).flat()]
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    },
    buildTableFooter({ observationClass: classInfo.observation })
  ]
}
