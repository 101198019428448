import { styled } from '@mui/material'

const Div = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
}))

const DivNumber = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  columnGap: '4px',
  height: '45px'
}))

const Number = styled('p')(({ theme }) => ({
  fontWeight: '400',
  lineHeight: '32px',
  textAlign: 'center',
  backgroundColor: 'white',
  width: '22px',
  height: '40px',
  padding: '4px',
  gap: '10px',
  borderRadius: '5px',
  fontSize: '1.7rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '3.2rem',
    width: '44px',
    height: '72px',
    lineHeight: '64px'
  }
}))

const Label = styled('div')(({ theme }) => ({
  fontFamily: 'Roboto',
  fontSize: '0.78rem',
  fontWeight: '700',
  letterSpacing: '0.15000000596046448px',
  textAlign: 'left',
  alignSelf: 'flex-start',
  color: 'white',
  marginTop: '0px',
  [theme.breakpoints.up('md')]: {
    marginTop: '12px'
  }
}))
export { Div, DivNumber, Number, Label }
