import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 20%;
  }
`

const TableCellRegister = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 10%;
  }
`

const TableCellNumber = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: center;
    padding: 6px 16px;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    width: 50%;
    padding: 6px;
    height: 40px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        padding: 0 8px;
      }
    }
  }
`

export { TableCell, TableCellTitle, TableCellNumber, TableCellRegister }
