import { lazy, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ButtonFooterV2 } from 'components/molecules'
import { useMakeService, useSnack } from 'services/hooks'
import { createAcces, selectProfile, selectSector } from './services'
import { breadcrumbsItems, initialState } from './constants'
import * as Styled from './style'

const Form = lazy(() => import('./components/Form'))

const CreateByUpdateAccess = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [listSector, setListSector] = useState([])
  const [listProfile, setListProfile] = useState([])
  const initialValues = initialState

  const paramsMakeService = {
    isUnits: true,
    setSnack
  }
  const { unitys } = useMakeService(paramsMakeService)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      createAcces({ setSnack, values, navigate })
    }
  })

  useEffect(() => {
    selectProfile({ setListProfile, setSnack })
    selectSector({ setListSector, setSnack })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      classNameHeader="goBack"
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems}
      setLoading={setLoading}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      // handleConfirmModal={() => onHandleConfirm()}
      onClickButton={() => setOpenModal(true)}
      content={
        <Styled.Form
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
            <Form
              setSnack={setSnack}
              values={formik.values}
              setFieldValue={formik.setFieldValue}
              unitys={unitys}
              listSector={listSector}
              listProfile={listProfile}
            />

            <Grid item xs={12} sm={12} md={12}>
              <ButtonFooterV2
                labelClose="Cancelar"
                labelConfirm="Criar"
                size="large"
                onClickClose={() => setOpenModal(true)}
              />
            </Grid>
          </Styled.GridContainer>
        </Styled.Form>
      }
    />
  )
}

export default CreateByUpdateAccess
