import PropTypes from 'prop-types'

import { Typography, Unstable_Grid2 as Grid } from '@mui/material'
import * as Styled from './styles'

const ButtonFlat = (props) => {
  const {
    backgroundColor,
    label,
    icon: Icon,
    className,
    onClick,
    orientation,
    fontColor,
    height
  } = props

  const isHorizontal = orientation === 'horizontal'

  return (
    <Styled.Button
      sx={{
        backgroundColor,
        height,
        '&.MuiButton-root:hover': { bgcolor: backgroundColor }
      }}
      className={className}
      onClick={onClick}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        flexDirection={isHorizontal ? 'row' : 'column'}
        spacing={1}
      >
        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ color: fontColor }} />
        </Grid>
        <Grid>
          <Typography
            color={fontColor}
            textTransform="none"
            fontSize="12px"
            fontWeight="bold"
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Styled.Button>
  )
}

export default ButtonFlat

ButtonFlat.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.elementType,
  onClick: PropTypes.func
}
