import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Grid,
  Accordion,
  IconButton,
  AccordionSummary,
  Tooltip,
  Box,
  Avatar
} from '@mui/material'
import {
  ExpandMore,
  ListAlt,
  MoreVert,
  Edit as EditIcon
} from '@mui/icons-material'
import { convertToParams, isEmpty } from 'services/helpers'
import { Button, InputSwitch, TextNotFound } from 'components/atoms'
import { DropDown, ModalConfirm, Select } from 'components/molecules'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import { MESSAGE } from '../../constants'
import { deleteClassLinked, getMenuItems } from './services'

import * as Styled from './style'

const ClassLinked = ({
  setIsUpdate,
  setLoadingOpen,
  listClassLinked,
  isFinalizedClassLinked,
  setIsFinalizedClassLinked
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [classSelected, setClassSelected] = useState()
  const params = Object.fromEntries([...searchParams])
  const { name, id } = params
  const open = Boolean(anchorEl)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)
  const handleShowModal = () => setIsOpen(true)

  const handleEditLinkedClass = ({ schoolClassId }) => {
    navigate(
      `${ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED_DETAILS_EDIT}?${convertToParams({
        isEdit: true,
        idProfessor: id,
        name,
        id: schoolClassId
      })}`
    )
  }

  const handleConfirm = () => {
    deleteClassLinked({
      id,
      setIsOpen,
      setIsUpdate,
      setLoadingOpen,
      idClass: classSelected.school_class_id
    })
  }
  const handleNavigation = (item) => {
    navigate(
      `${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS}?${convertToParams({
        id: item.school_class_id
      })}`
    )
  }

  return (
    <>
      <Styled.Grid
        $flex
        $isColumn
        container
        $marginTop="25px"
        spacing={{ xs: 0, sm: 2 }}
        $AlignItems="flex-end"
      >
        <Styled.Grid xs={12} sm={12} md={12}>
          <Button
            type="button"
            color="primary"
            value="Novo Vínculo"
            className={TAG_MANAGER.rh_btn_acessa_vincularTurma}
            onclick={() =>
              navigate(
                `${ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED}?${convertToParams({
                  id,
                  name
                })}`
              )
            }
          />
        </Styled.Grid>
      </Styled.Grid>

      <Styled.Grid $maxWidth="250px !important" xs={12} sm={12} md={4}>
        <Styled.Switch>
          <InputSwitch
            label="Turmas encerradas"
            checked={isFinalizedClassLinked}
            onClick={(e) => setIsFinalizedClassLinked(e.target.checked)}
          />
        </Styled.Switch>
      </Styled.Grid>

      {isEmpty(listClassLinked) && (
        <TextNotFound icon={<ListAlt />} text={MESSAGE.NOT_CLASS_LINKED} />
      )}

      {!isEmpty(listClassLinked) && (
        <>
          {listClassLinked?.map((item) => (
            <Accordion key={item.course}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMore />}
              >
                <Styled.WrapperSummary>
                  <Styled.SummaryLeft>
                    <Styled.Text>
                      <b>Turma {item.school_class_code}</b>
                    </Styled.Text>
                    <Tooltip title="Editar vínculo" arrow>
                      <IconButton
                        onClick={() =>
                          handleEditLinkedClass({
                            schoolClassId: item.school_class_id
                          })
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Styled.SummaryLeft>
                  <Styled.SummaryRigth>
                    <Tooltip title="Ativos" arrow>
                      <Avatar
                        sx={{
                          bgcolor: (t) => t.palette.success.main,
                          width: 32,
                          height: 32,
                          fontSize: 14
                        }}
                      >
                        {item.active_discipline_links || 0}
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Inativos" arrow>
                      <Avatar
                        sx={{
                          bgcolor: (t) => t.palette.red.light,
                          mr: (t) => t.spacing(1),
                          width: 32,
                          height: 32,
                          fontSize: 14
                        }}
                      >
                        {item.inactive_discipline_links || 0}
                      </Avatar>
                    </Tooltip>
                  </Styled.SummaryRigth>
                </Styled.WrapperSummary>
              </AccordionSummary>

              <Styled.AccordionDetails>
                <Styled.Grid
                  $flex
                  container
                  $AlignItems="center"
                  $JustifyContent="flex-start"
                  spacing={{ xs: 2, sm: 2 }}
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Styled.Text $isLink onClick={() => handleNavigation(item)}>
                      <b> {item.school_class_code}</b>
                    </Styled.Text>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Styled.Text>
                      <b>Curso</b> {item.course}
                    </Styled.Text>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Styled.Text>
                      <b>Nivel</b> {item.level}
                    </Styled.Text>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={item.school_class_pole ? 6 : 11.5}
                  >
                    <Styled.Text>
                      <b>Unidade</b> {item.unit}
                    </Styled.Text>
                  </Grid>
                  {item.school_class_pole && (
                    <Grid item xs={12} sm={12} md={5}>
                      <Styled.Text>
                        <b>Polo</b> {item.pole}
                      </Styled.Text>
                    </Grid>
                  )}
                </Styled.Grid>
              </Styled.AccordionDetails>
            </Accordion>
          ))}
        </>
      )}

      <ModalConfirm
        size="medium"
        open={isOpen}
        title="Excluir"
        textButtonConfirm="Sim"
        textButtonNotConfirm="Não"
        colorButtonConfirm="error"
        icon={<Styled.IconWarningAmber />}
        handleClose={() => setIsOpen(!isOpen)}
        onClickConfirm={() => handleConfirm()}
        onClickNotConfirm={() => setIsOpen(!isOpen)}
        classNameBtnConfirm={TAG_MANAGER.rh_btn_confirma_excluiVinculo}
        message="Deseja remover esse vínculo de turma? Você perderá todas as informações sobre ele."
      />
    </>
  )
}

export { ClassLinked }
