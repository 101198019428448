function getSecretaryPermissions(permissions) {
  const occurrence = permissions?.occurrence || {}
  const expedition = permissions?.occurrence || {}
  const secretaryStudents =
    permissions?.student?.files?.search?.secretary_student || {}
  const documentStudent =
    permissions?.student?.files?.search?.secretary_student?.documents?.POST ||
    {}
  const AdditionalActivity =
    permissions?.student?.files?.search?.secretary_student
      ?.complementary_activity
  const AdditionalActivityAvaliation =
    permissions?.student?.files?.complementary_activity || {}
  const requirements = permissions?.requirements || {}
  const listRequirements = requirements?.GET
  const student = permissions?.student || {}
  const declarations =
    student?.files?.search?.secretary_student?.declarations || {}
  const createRequirements = requirements?.POST
  const updateRequirements = requirements?.PUT
  const schoolClass = permissions?.school_class || {}
  const secretaryUseOfStudies =
    permissions?.student?.files?.search?.secretary_student
      ?.academic_performance || {}
  const secretaryDocument =
    permissions?.student?.files?.personal_documents || {}
  const justifyAbsence = student?.justification?.abcense || {}
  const secretaryContract = permissions?.student?.files?.contracts || {}
  const secretaryDocumentContract = permissions?.student?.files?.doc_cont || {}
  const academicPerformances =
    permissions?.student?.files?.academic_performance || {}
  const secretaryGrouping = permissions?.discipline?.grouping || {}

  const SECRETARY_PERMISSIONS = {
    SECRETARY: Boolean(secretaryStudents?.GET),
    SECRETARY_OCCURRENCES: Boolean(occurrence?.GET),
    SECRETARY_OCCURRENCES_CREATE: Boolean(occurrence?.POST),
    SECRETARY_EXPEDITION_CREATE: Boolean(expedition?.POST),
    SECRETARY_OCCURRENCES_EDIT: Boolean(occurrence?.GET),
    SECRETARY_DOCUMENT_STUDENT_POST: Boolean(documentStudent),
    SECRETARY_REQUISITION: Boolean(listRequirements),
    SECRETARY_REQUISITION_EDIT: Boolean(updateRequirements),
    SECRETARY_REQUISITION_CREATE: Boolean(createRequirements),
    SECRETARY_STUDENTS: Boolean(secretaryStudents?.GET),
    SECRETARY_STUDENTS_CREATE: Boolean(secretaryStudents?.GET),
    SECRETARY_STUDENTS_FINALIZE: Boolean(secretaryStudents?.POST),
    SECRETARY_STUDENTS_EDIT: Boolean(secretaryStudents?.PUT),
    SECRETARY_STUDENTS_DECLARATION: Boolean(secretaryStudents?.GET),
    SECRETARY_STUDENTS_CREATE_DECLARATION: Boolean(secretaryStudents?.GET),
    SECRETARY_STUDENTS_REGISTER: Boolean(secretaryStudents?.GET),
    SECRETARY_STUDENT_DECLARATION: Boolean(declarations?.GET),
    SECRETARY_STUDENTS_ADDITIONAL_ACTIVITY: Boolean(AdditionalActivity?.GET),

    SECRETARY_STUDENTS_JUSTIFY_ABSENCE: Boolean(justifyAbsence?.GET),
    SECRETARY_STUDENTS_JUSTIFY_ABSENCE_CREATE: Boolean(justifyAbsence?.POST),
    SECRETARY_STUDENTS_JUSTIFY_ABSENCE_DELETE: Boolean(justifyAbsence?.DELETE),

    SECRETARY_STUDENTS_ADDITIONAL_ACTIVITY_AVALIATION: Boolean(
      AdditionalActivityAvaliation?.GET
    ),
    SECRETARY_SCHOOL_CLASS: Boolean(schoolClass?.GET),
    SECRETARY_REGISTER_OCCURRENCES_CREATE: Boolean(occurrence?.POST),
    SECRETARY_REGISTER_OCCURRENCES_EDIT: Boolean(occurrence?.GET),
    SECRETARY_STUDENTS_HISTORY: Boolean(secretaryStudents?.GET),
    SECRETARY_SCHOOL_CLASS_CREATE: Boolean(schoolClass?.POST),
    SECRETARY_SCHOOL_NEW_CLASS: Boolean(schoolClass?.POST),
    SECRETARY_SCHOOL_REENROLL: Boolean(schoolClass?.GET),
    SECRETARY_SCHOOL_CLASS_DETAILS: Boolean(schoolClass?.GET),
    SECRETARY_SCHOOL_CLASS_STUDENTLIST: Boolean(schoolClass?.GET),
    SECRETARY_SCHOOL_CLASS_EDIT_DISCIPLINE: Boolean(schoolClass?.PUT),
    SECRETARY_DOCUMENT_LIST: Boolean(secretaryDocument?.GET),
    SECRETARY_CONTRACT_LIST: Boolean(secretaryContract?.GET),
    SECRETARY_STUDENTS_GROUPING: Boolean(secretaryGrouping?.GET),
    SECRETARY_DOCUMENT_CONTRACT: Boolean(secretaryDocumentContract?.GET),
    SECRETARY_SCHOOL_CLASS_BIND_UNBINDED: Boolean(schoolClass?.GET),
    SECRETARY_USE_OF_STUDIES_LIST: Boolean(secretaryUseOfStudies?.GET),
    SECRETARY_ACADEMIC_PERFORMANCES: Boolean(academicPerformances?.GET),
    SECRETARY_ACADEMIC_PERFORMANCES_EDIT: Boolean(academicPerformances?.PUT),
    ADMIN_SECRETARY_CLASSES_DASHBOARD: Boolean(schoolClass?.dashboard?.GET),
    ADMIN_SECRETARY_CLASSES_DASHBOARD_CLASS: Boolean(
      schoolClass?.dashboard?.GET
    )
  }

  return SECRETARY_PERMISSIONS
}

export default getSecretaryPermissions
