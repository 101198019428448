import {
  UploadFile,
  AttachMoney,
  MilitaryTech,
  School,
  PersonalVideo
} from '@mui/icons-material'
import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'
import { getUserFromLocalStorage } from 'services/helpers'
import { ChangePassword, JoyClassRedirection } from 'components/pages/Shared'
import {
  Discipline,
  DocumentsRequestsStudent,
  FinancialStudent,
  MyScores,
  ReEnrollment,
  Contract,
  StudentHub
} from 'components/pages/Student'
import theme from 'theme/designTokens'

export const iconProps = {
  color: theme.palette.primary.white
}

const { isNovotec } = getUserFromLocalStorage()

const STUDENTS_ROUTES = [
  {
    component: Discipline,
    exact: true,
    icon: <School sx={{ ...iconProps }} />,
    label: 'Disciplinas',
    path: ROUTE.STUDENT_DISCIPLINE,
    permissions: ACL.student,
    isAllowed: () => true
  },
  {
    component: DocumentsRequestsStudent,
    exact: true,
    icon: <UploadFile sx={{ ...iconProps }} />,
    label: 'Documentos e Solicitações',
    path: ROUTE.STUDENT_DOCUMENTS,
    permissions: ACL.student,
    isAllowed: () => true
  },
  {
    ...(!isNovotec && {
      component: MyScores,
      exact: true,
      icon: <MilitaryTech sx={{ ...iconProps }} />,
      label: 'Minhas Notas',
      path: ROUTE.STUDENT_MY_SCORES,
      permissions: ACL.student,
      isAllowed: () => true
    })
  },
  {
    component: FinancialStudent,
    exact: true,
    icon: <AttachMoney sx={{ ...iconProps }} />,
    label: 'Financeiro',
    path: ROUTE.STUDENT_FINANCIAL,
    permissions: ACL.student,
    isAllowed: () => true
  },
  {
    component: JoyClassRedirection,
    exact: true,
    icon: <PersonalVideo sx={{ ...iconProps }} />,
    label: 'JoyClass',
    path: 'JoyClass',
    permissions: ACL.student,
    isAllowed: () => true
  },
  {
    component: ChangePassword,
    exact: true,
    path: ROUTE.STUDENT_UPDATE_PASSWORD,
    permissions: ACL.student,
    isAllowed: () => true
  },
  {
    component: ReEnrollment,
    exact: true,
    path: ROUTE.STUDENT_RE_ENROLLMENT,
    permissions: ACL.student,
    isAllowed: () => true
  },
  {
    component: Contract,
    exact: true,
    path: ROUTE.STUDENT_CONTRACT,
    permissions: ACL.student,
    isAllowed: () => true
  },
  {
    component: StudentHub,
    exact: true,
    path: ROUTE.STUDENT_HUB,
    permissions: ACL.student,
    isAllowed: () => true
  }
]

export default STUDENTS_ROUTES
