import styled, { css } from 'styled-components'
import {
  Step as MuiStep,
  Stepper as MuiStepper,
  Grid as MuiGrid
} from '@mui/material'
import { TextField as MuiTextField } from 'components/molecules'

const Stepper = styled(MuiStepper)`
  span:first-child {
    gap: 5px;
    font-size: 14px;
    font-weight: 700;
    flex-direction: column;
  }
  &.MuiStepper-root {
    align-items: center;
    margin: 24px 172px;
  }
`

const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    width: 300px;
  }
`

const BoxSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > p:last-of-type {
    text-align: center;
  }
  & > label:first-of-type {
    margin-bottom: 24px;
  }
`

const Step = styled(MuiStep)`
  &.MuiStep-root {
    &.Mui-completed {
      & > span > span > span {
        color: ${({ theme }) => theme.palette.success.main};
      }

      & > span > span > svg {
        color: ${({ theme }) => theme.palette.success.main};
      }
    }
  }
`
const Grid = styled(MuiGrid)`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`
const Box = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export { Stepper, BoxHeader, TextField, BoxSwitch, Step, Grid, Box }
