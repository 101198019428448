import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { addAsteristic } = utilsPDF

const handleGrade = ({ grade, disciplineHasAlreadyStarted, minimumGrade }) => {
  if (grade) {
    const gradeFixed = grade.toFixed(2).replace('.', ',')
    return grade < minimumGrade ? addAsteristic(gradeFixed) : gradeFixed
  }

  return disciplineHasAlreadyStarted ? addAsteristic('0,0') : '-'
}

const buildTableBody = (students, indexChunk, minimumGrade) =>
  students.map((studentEl, index) => {
    const { student, gradeDetails } = studentEl
    return [
      { text: index + 1, style: ['tableBodyCell'] },
      { text: student.name, style: ['tableBodyCell'] },
      ...gradeDetails[indexChunk]
        .map((subject) => [
          {
            text: handleGrade({
              grade: subject.grade.finalGrade,
              disciplineHasAlreadyStarted: subject.disciplineHasAlreadyStarted,
              minimumGrade
            }),
            style: ['tableBodyCell'],
            alignment: 'center'
          },
          {
            text: `${Math.floor(subject.frequency.absenceInPercentage)}%`,
            style: ['tableBodyCell'],
            alignment: 'center'
          }
        ])
        .flat()
    ]
  })
export default buildTableBody
