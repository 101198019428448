import { Table as MuiTable, TableBody } from '@mui/material'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'
import { useSortTable } from 'services/hooks'
import { paginationFilter } from 'services/helpers'
import { defaultItemsPerPage } from '../../constants'
import { headCells, headColumns } from './constants'
import TableFooter from './TableFooter'
import * as Styled from './style'

const Table = ({
  rows,
  setFilter,
  filter,
  page,
  totalPage,
  setPage,
  count,
  handleSetPage,
  handleLink
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    rows,
    headColumns.discipline
  )

  return (
    <>
      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />

            <TableBody>
              {sortTable?.map((row) => (
                <TableFooter
                  key={row?.subjectId}
                  row={row}
                  handleLink={() => handleLink(row?.subjectId)}
                />
              ))}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={page}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setPage(defaultItemsPerPage)
              setFilter(e.target.value)
            }}
          />
        )}
      </Styled.Paper>
    </>
  )
}

export default Table
