import React, { Suspense } from 'react'

import { LoadingPage, Snackbar } from 'components/molecules'
import { HeaderPage } from 'components/organisms'

import { ToastComponent } from 'components/atoms'
import * as Styled from './style'

const TemplateNewBankBilling = ({
  loadingOpen,
  snackProps,
  form,
  classNameHeader,
  labelButton,
  breadcrumbsItems,
  startIconButton,
  buttonAction,
  headerContent,
  modal
}) => (
  <Suspense fallback={<LoadingPage open />}>
    <Styled.Content>
      <LoadingPage open={loadingOpen} />

      <HeaderPage
        labelCallToAction={labelButton}
        classNameCallToAction={classNameHeader}
        breadcrumbsItems={breadcrumbsItems}
        startIcon={startIconButton}
        callToAction={buttonAction}
        divider
      >
        {headerContent}
      </HeaderPage>

      <div>{form}</div>
      <div>{modal}</div>

      {snackProps && (
        <Snackbar
          message={snackProps.snack.message}
          severity={snackProps.snack.severity}
          shouldOpen={snackProps.snackOpen}
        />
      )}

      <ToastComponent />
    </Styled.Content>
  </Suspense>
)

export default TemplateNewBankBilling
