import { lazy, useEffect, useState } from 'react'
import { Form as FormikForm, Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { usePaginationBasic, useSnack } from 'services/hooks'
import {
  IsEmpty,
  exportSpreadsheetByXLSX,
  formatDateEnv,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import { getDocumentsStatus, getDocumentsStatusXLSX } from 'services/api/admin'
import {
  defaultPage,
  nameAccordion,
  MESSAGE,
  defaultItemsPerPage,
  countDefault,
  SCHOOL_CLASS
} from './constants'
import schema from './schema'
import { initialValue } from './config'
import { useStoreDocuments } from '../Store/useStoreDocuments'

const Form = lazy(() => import('./components/Form'))
const Table = lazy(() => import('./components/Table'))

const ListDocuments = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const snackProps = useSnack()
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [documentsList, setDocumentsList] = useState([])
  const [summary, setSummary] = useState({
    pending: 0,
    conferred: 0,
    rejected: 0
  })
  const [statusFilter, setStatusFilter] = useState(null)

  const isSearchParam = location?.state?.savedSearch || false

  const { formValues, setFormValues, resetFormValues } = useStoreDocuments()

  const { page, totalPage, items, setPage, handleSetPage } = usePaginationBasic(
    documentsList,
    filter,
    count
  )
  const { setSnack } = snackProps

  const requestFilters = (values) => ({
    ...(values.searchType &&
      values.searchBy && { [values.searchType]: values.searchBy }),
    ...(values.unit && { unit: values.unit }),
    ...(values.status && { status: values.status }),
    begin_at: formatDateEnv(values.beginAt),
    end_at: formatDateEnv(values.endAt)
  })

  const onSubmit = async (values) => {
    setSnack('', '')
    setLoading(true)
    const paginationData = {
      limit: filter,
      page: page || defaultPage
    }

    const params = {
      ...paginationData,
      ...requestFilters(values)
    }
    const { data: response, error, status } = await getDocumentsStatus(params)

    setLoading(false)
    if (error) {
      setNotSearch(true)
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDocumentStatus
      })
      return
    }

    const { pending, conferred, rejected, data } = response

    if (!values.status) setSummary({ pending, conferred, rejected })
    setCount(response?.count)
    setDocumentsList(data)
    setNotSearch(false)
    setFormValues(values)
    setExpanded(false)
  }

  const cancelAutomaticSearch = () => {
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  const onClear = () => {
    setFormValues(null)
    resetFormValues()
    setDocumentsList([])
    setNotSearch(true)
    cancelAutomaticSearch()
  }

  useEffect(
    () => formValues?.beginAt && formValues?.endAt && onSubmit(formValues),
    [page, filter]
  )

  useEffect(() => {
    if (isSearchParam && formValues?.beginAt && formValues?.endAt) {
      return onSubmit(formValues)
    }

    resetFormValues()
    cancelAutomaticSearch()
    return setDocumentsList([])
  }, [isSearchParam])

  const initialState = mergeInitialValues(
    initialValue,
    isSearchParam && formValues
  )

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH_PERFOMED
    : MESSAGE.NOT_FOUND_SEARCH

  const onStatusFilter = async (status) => {
    setStatusFilter((state) => {
      const newStatus = state === status ? null : status
      onSubmit({ ...formValues, status: newStatus })
      return newStatus
    })
  }

  const onFilterPending = async () => onStatusFilter('pending')

  const onFilterConferred = async () => onStatusFilter('conferred')

  const onFilterRejected = async () => onStatusFilter('rejected')

  const onExportReport = async (values) => {
    setLoading(true)
    const {
      data: response,
      status,
      error
    } = await getDocumentsStatusXLSX(requestFilters(values))

    setLoading(false)
    if (error) {
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToDownloadListDocumentStatus
      })
      return
    }

    exportSpreadsheetByXLSX(response, 'Documentos Pessoais.xlsx')
  }

  const onExportPending = async () =>
    onExportReport({ ...formValues, status: 'pending' })

  const onExportConferred = async () =>
    onExportReport({ ...formValues, status: 'conferred' })

  const onExportRejected = async () =>
    onExportReport({ ...formValues, status: 'rejected' })

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Secretaria - Documentos Pessoais"
      snackProps={snackProps}
      nameAccordion={nameAccordion}
      loadingOpen={loading}
      listItems={documentsList}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      expanded={expanded}
      setExpanded={setExpanded}
      accordion={
        <Formik
          initialValues={initialState}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={schema}
        >
          {(props) => (
            <FormikForm
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                setLoading={setLoading}
                setCount={setCount}
                setFilter={setFilter}
                setPage={setPage}
                onClear={onClear}
                setSnack={setSnack}
                {...props}
              />
            </FormikForm>
          )}
        </Formik>
      }
    >
      {documentsList && !IsEmpty(documentsList) && (
        <Table
          rows={items}
          filter={filter}
          setFilter={setFilter}
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          count={count}
          handleSetPage={handleSetPage}
          setLoading={setLoading}
          setSnack={setSnack}
          pending={summary.pending}
          conferred={summary.conferred}
          rejected={summary.rejected}
          onFilterPending={onFilterPending}
          onFilterConferred={onFilterConferred}
          onFilterRejected={onFilterRejected}
          onExportPending={onExportPending}
          onExportConferred={onExportConferred}
          onExportRejected={onExportRejected}
          statusFilter={statusFilter}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListDocuments
