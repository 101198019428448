import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import { Clear } from '@mui/icons-material'
import {
  useActionSelected,
  useMakeService,
  usePaginationBasic,
  useSnack
} from 'services/hooks'
import { ERROR, ROUTE } from 'services/constants'
import {
  IsEmpty,
  getErrorMessage,
  mergeInitialValues,
  messageErroOrSuccess,
  updateErrorMessage
} from 'services/helpers'
import {
  deleteDeclarations,
  getDeclarations
} from 'services/api/admin/system/Declarations'
import { cleanObject } from 'services/helpers/cleanObject'
import FEEDBACK_SNACK from 'feedBackSnack'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import {
  TableDeclaration,
  FormListDeclaretion
} from './components/ListDeclarations'
import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  MESSAGE,
  defaultTotalPage,
  initialState,
  modalConfirm
} from './constants'
import { isDisabledButtonList } from './helpers'
import { useDeclarations } from './context/FormContext'
import * as Styled from './style'

const Declarations = () => {
  const location = useLocation()
  const [count, setCount] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [filter, setFilter] = useState(DEFAULT_ITEMS_PER_PAGE)
  const [listDeclarations, setListDeclarations] = useState([])
  const { onActionSelected, selected, onResetAction } = useActionSelected()
  const { formValues, setFormValues, resetFormValues } = useDeclarations()
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const {
    page: pageActual,
    totalPage,
    setPage
  } = usePaginationBasic(listDeclarations, filter, count)

  const paramsMakeService = {
    isLevel: true,
    setSnack
  }

  const isSearchParam = location?.state?.savedSearch
  const { level } = useMakeService(paramsMakeService)

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  const handleSearch = async (values) => {
    setLoadingVisibility(true)

    const params = cleanObject({
      ...(values.level && { level: values.level }),
      type: values.issuance,
      name: values.name,
      limit: filter || defaultTotalPage,
      page: pageActual || DEFAULT_PAGE
    })

    const { data, error, status } = await getDeclarations(params)
    setLoadingVisibility(false)
    setCount(data?.count)
    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDeclaretion
      })
    }

    if (error) {
      const message = getErrorMessage(error, status)
      return setSnack(message, ERROR)
    }

    setNotSearch(false)

    return setListDeclarations(data?.data)
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      handleSearch(values)
      setFormValues(values)
    }
  })

  const onClear = () => {
    setNotSearch(true)
    resetFormValues()
    formik.resetForm({})
    setListDeclarations([])
  }

  const handleDelete = async (id) => {
    const { error, status } = await deleteDeclarations(id)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDeleteDeclaretion
      })
    }

    messageErroOrSuccess({
      error,
      setSnack: snackProps.setSnack,
      status,
      feedbackMessage: error,
      successMessage: 'Declaração excluída com sucesso'
    })

    return handleSearch(formik.values)
  }

  const handleClear = () => {
    setNotSearch(true)
    setListDeclarations([])
    setFormValues(initialState)
    formik.resetForm(initialValues)
  }

  useEffect(() => {
    if (formik.values.level) handleSearch(formik.values)
  }, [pageActual, filter])

  useEffect(() => {
    if (selected?.type) setIsOpen(selected?.type === modalConfirm)
  }, [selected])

  return (
    <>
      <TemplateDefaultFormAndResult
        snackProps={snackProps}
        listItems={listDeclarations}
        labelButtonHeader="Nova declaração"
        loadingVisibility={loadingVisibility}
        linkHeader={ROUTE.CREATE_DECLARATIONS}
        iconInformative={messageInformative.icon}
        titleHeader="<b>Sistema - </b> Tipos de Declarações"
        messageInformative={messageInformative.text}
        classNameHeader={TAG_MANAGER.sistema_btn_cria_novaDeclaracao}
        tagmanagerModal={TAG_MANAGER.sistema_btn_cria_novaDeclaracao}
        formHeader={
          <Styled.Form
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
              <FormListDeclaretion
                level={level}
                setSnack={setSnack}
                values={formik.values}
                setFieldValue={formik.setFieldValue}
              />
              <Styled.Grid item xs={12} sm={12} md={3}>
                <ButtonFooterV2
                  size="medium"
                  labelClose="Limpar"
                  labelConfirm="Buscar"
                  startIcon={<Clear />}
                  onClickClose={onClear}
                  disabledClose={isDisabledButtonList(formik.values)}
                  disabledConfirm={isDisabledButtonList(formik.values)}
                  className={TAG_MANAGER.sistema_btn_busca_declaracao}
                />
              </Styled.Grid>
            </Styled.GridContainer>
          </Styled.Form>
        }
      />

      {listDeclarations && !IsEmpty(listDeclarations) && (
        <TableDeclaration
          filter={filter}
          formik={formik}
          setPage={setPage}
          setFilter={setFilter}
          totalPage={totalPage}
          pageActual={pageActual}
          items={listDeclarations}
          onManualEnrollment={(type, item) => onActionSelected(type, item)}
        />
      )}
      <ModalConfirm
        open={isOpen}
        title="Excluir"
        textButtonConfirm="Sim"
        textButtonNotConfirm="Não"
        colorButtonConfirm="error"
        onClickNotConfirm={() => {
          setIsOpen(!isOpen)
          onResetAction()
        }}
        onClickConfirm={() => {
          setIsOpen(false)
          onResetAction()
          handleDelete(selected.data.id)
        }}
        icon={<Styled.IconWarningAmber />}
        message="Deseja realmente excluir essa declaração? Não será possível recuperar após confirmar."
        classNameBtnConfirm={TAG_MANAGER.sistema_btn_confirma_excluirDeclaracao}
      />
    </>
  )
}

export default Declarations
