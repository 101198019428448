/* eslint-disable camelcase */
import { formatDateEnv } from 'services/helpers'

const parseResponse = (data, isName) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: isName ? name : id, label: name }
    return [...acc, newItem]
  }, [])

const parserDate = (dataStr) => {
  const data = new Date(dataStr)

  data.setDate(data.getDate() - 1)

  return data.toISOString().split('T')[0]
}

const parserAddDay = (dataStr) => {
  const data = new Date(dataStr)

  data.setDate(data.getDate() + 1)

  return data.toISOString().split('T')[0]
}

const handleCompetenceChange = ({ newValue, formik }) => {
  formik.setFieldValue('competence', newValue)

  const START_DAY = 17
  const END_DAY = 16
  const PAYMENT_DAY = 11
  const START_MONTH_ADJUSTMENT = 1
  const PAYMENT_MONTH_ADJUSTMENT = 1

  const startPeriod = new Date(
    newValue.getFullYear(),
    newValue.getMonth() - START_MONTH_ADJUSTMENT,
    START_DAY
  )

  const endPeriod = new Date(
    newValue.getFullYear(),
    newValue.getMonth(),
    END_DAY
  )

  const paymentDateCalc = new Date(
    newValue.getFullYear(),
    newValue.getMonth() + PAYMENT_MONTH_ADJUSTMENT,
    PAYMENT_DAY
  )

  formik.setFieldValue('counting_start_date', formatDateEnv(startPeriod))
  formik.setFieldValue('counting_end_date', formatDateEnv(endPeriod))
  return formik.setFieldValue('payment_date', formatDateEnv(paymentDateCalc))
}

export { parseResponse, parserDate, parserAddDay, handleCompetenceChange }
