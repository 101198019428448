export default function getPDFPermissions(permissions) {
  const pdf = permissions?.pdf || {}
  const poc = pdf?.poc || {}

  const PDF_PERMISSIONS = {
    PDF_POC: Boolean(poc)
  }

  return PDF_PERMISSIONS
}
