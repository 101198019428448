import styled, { css } from 'styled-components'
import { Link as RouteLink } from 'react-router-dom'
import { TableCell } from '@mui/material'

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: left;
    max-width: 200px;
    padding: 6px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:nth-child(3).MuiTableCell-root {
    max-width: 165px;
  }
  &:nth-child(5).MuiTableCell-root {
    max-width: 130px;
  }
`

const BoxLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`

const Box = styled.div`
  width: 32px;
  padding: 7px;
  display: flex;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  align-items: center;
  border-radius: 16px;
  letter-spacing: 0.16px;
  justify-content: center;
  color: ${({ theme }) => theme.palette.primary.white};
  background-color: ${({ theme }) => theme.palette.success.main};

  ${({ $canceled }) =>
    $canceled &&
    css`
      background-color: ${({ theme }) => theme.palette.red.light};
    `}
  ${({ $absent }) =>
    $absent &&
    css`
      background-color: ${({ theme }) => theme.palette.yellow.warning};
    `}
`
const ContainerStudentStatus = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ContainerUnitName = styled(ContainerStudentStatus)`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

const StyledTableCellTitle = styled(TableCell)`
  &.MuiTableCell-root {
    max-width: 235px;
    text-align: left;
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
    padding: 6px 16px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
      }
    }
  }
`

const Link = styled(RouteLink)`
  text-transform: uppercase;
`

const ContainerIcon = styled.div`
  & .MuiButtonBase-root {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`

export {
  Box,
  Link,
  BoxLink,
  ContainerIcon,
  StyledTableCell,
  ContainerUnitName,
  StyledTableCellTitle,
  ContainerStudentStatus
}
