import { utilsPDF } from '../../shared/utils'
import { buildTableBody } from './Body'
import { buildTableFooter } from './Footer'
import { buildTableHeader } from './Header'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

const handleStudents = ({ students }) =>
  students.map((studentElement) => {
    const student = {
      ...studentElement,
      gradeDetails: studentElement.gradeDetails.map((gradeDetail) =>
        gradeDetail.map((gradeDetailsChunk) => ({
          ...gradeDetailsChunk,
          disciplineHasAlreadyStarted:
            new Date(gradeDetailsChunk.discipline.beginAt) <= new Date()
        }))
      )
    }
    return student
  })

export default function buildTable({
  subjects,
  students,
  classInfo,
  indexChunk,
  classWorkload,
  isPartialReport
}) {
  const numberOfClasses = classInfo.numberOfClasses || 5
  const schoolDays = Math.round(classWorkload / numberOfClasses)

  const studentsHandled = handleStudents({
    students
  })

  return [
    {
      table: {
        body: [
          ...buildTableHeader({
            subjects,
            schoolDays,
            classWorkload,
            isPartialReport
          }),
          ...buildTableBody(studentsHandled, indexChunk, classInfo.minimumGrade)
        ],
        widths: ['auto', 140, ...subjects.map((_) => ['*', '*']).flat(), 50]
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    },
    buildTableFooter()
  ]
}
