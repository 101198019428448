/* eslint-disable camelcase */
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { usePaginationBasic, useSnack } from 'services/hooks'
import TAG_MANAGER from 'tagManager'
import { ROUTE } from 'services/constants'
import { AccordionFilter, DatePicker, PageTitle } from 'components/atoms'
import { ButtonFooterV2, CardNumber } from 'components/molecules'
import theme from 'theme/designTokens'
import { IsEmpty, formatDate } from 'services/helpers'
import { useFormik } from 'formik'
import { Clear } from '@mui/icons-material'
import ModalEmail from 'components/pages/Admin/Secretary/Students/StudentRegister/components/ModalEmail'
import { breadcrumbsItems, isDisabledButton } from './helpers'
import * as Styled from './style'
import TableDashClass from '../TableDashClass'
import { defaultItemsPerPage, defaultPage, initialState } from './constants'
import {
  getCardsNumber,
  getDashClass,
  getListStudent,
  handleFilterListByStatus,
  onSubmitEmail
} from './service'

const DashboardClass = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const state = useLocation()?.state

  const endDate = state?.endDate || state?.row?.end_at
  const startDate = state?.startDate || state?.row?.start_at

  const [searchParams] = useSearchParams()

  const { id_turma } = Object.fromEntries([...searchParams])

  const [expanded, setExpanded] = useState('class')
  const [listStudents, setListStudents] = useState({
    initial: [],
    filtered: []
  })
  const [listEmail, setListEmail] = useState([])
  const [detailsDash, setDetailsDash] = useState([])
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [isOpenModalEmail, setIsOpenModalEmail] = useState(false)

  const {
    page: pageActual,
    totalPage,
    items,
    totalItems,
    setPage
  } = usePaginationBasic(listStudents.filtered, filter)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: (values) => {
      getDashClass({
        values,
        setSnack,
        setDetailsDash,
        classId: id_turma,
        setLoadingVisibility
      })

      getListStudent({
        filter,
        values,
        setSnack,
        setListStudents,
        classId: id_turma,
        setLoadingVisibility,
        page: pageActual || defaultPage
      })
      setExpanded(false)
    }
  })

  const handleClear = () => {
    formik.resetForm(initialState)
  }

  const { values } = formik

  useEffect(() => {
    getDashClass({
      values,
      endDate,
      setSnack,
      startDate,
      setDetailsDash,
      classId: id_turma,
      setLoadingVisibility
    })
    getListStudent({
      filter,
      values,
      endDate,
      setSnack,
      startDate,
      setListStudents,
      classId: id_turma,
      setLoadingVisibility,
      page: pageActual || defaultPage
    })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loadingVisibility}
      snackProps={snackProps}
      onClickButton={() =>
        navigate(ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS, {
          state: { savedSearch: true }
        })
      }
      breadcrumbsNotLink={breadcrumbsItems(detailsDash?.class_code)}
      classNameHeader="goBack"
      tagmanagerModal={TAG_MANAGER.secretary_btn_BackApplication}
      content={
        <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
          <Styled.Grid isRow item xs={12} sm={12} md={12}>
            <AccordionFilter
              title="Selecione um período para filtrar os resultados."
              expanded={expanded}
              nameAccordion="class"
              setExpanded={setExpanded}
              margin
            >
              <Styled.Form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
                  <>
                    <Grid item xs={4.7}>
                      <DatePicker
                        fullWidth
                        label="De"
                        id="startDate"
                        value={startDate || formik.values?.startDate || null}
                        onChange={(newValue) => {
                          formik.setFieldValue('startDate', newValue)
                        }}
                      />
                    </Grid>

                    <Grid item xs={4.7}>
                      <DatePicker
                        fullWidth
                        id="endDate"
                        label="Até"
                        value={endDate || formik.values?.endDate || null}
                        onChange={(newValue) => {
                          formik.setFieldValue('endDate', newValue)
                        }}
                      />
                    </Grid>
                  </>
                  <Styled.Grid item xs={12} sm={12} md={2.5}>
                    <ButtonFooterV2
                      size="medium"
                      labelClose="Limpar"
                      labelConfirm="Buscar"
                      startIcon={<Clear />}
                      onClickClose={handleClear}
                      disabledClose={isDisabledButton(formik.values)}
                      disabledConfirm={isDisabledButton(formik.values)}
                    />
                  </Styled.Grid>
                </Styled.GridContainer>
              </Styled.Form>
            </AccordionFilter>
          </Styled.Grid>
          <Styled.Grid isRow item xs={12} sm={12} md={12}>
            <PageTitle
              padding="0"
              title={`<b>Dashboard de turma - </b> ${detailsDash?.class_code}`}
            />
          </Styled.Grid>
          <Styled.Grid item xs={12} sm={12} md={6}>
            <Styled.Title>
              <Typography variant="subtitle1" fontWeight="bold">
                Curso:
              </Typography>
              <Typography variant="subtitle1" ml={1}>
                {detailsDash?.course}
              </Typography>
            </Styled.Title>
            <Styled.Title>
              <Typography variant="subtitle1" fontWeight="bold">
                Unidade:
              </Typography>
              <Typography variant="subtitle1" ml={1}>
                {detailsDash?.unit}
              </Typography>
            </Styled.Title>
            <Styled.Title>
              <Typography variant="subtitle1" fontWeight="bold">
                Polo:
              </Typography>
              <Typography variant="subtitle1" ml={1}>
                {detailsDash?.pole}
              </Typography>
            </Styled.Title>
            <Styled.Title>
              <Typography variant="subtitle1" fontWeight="bold">
                Grupo:
              </Typography>
              <Typography variant="subtitle1" ml={1}>
                {detailsDash?.group}
              </Typography>
            </Styled.Title>
          </Styled.Grid>
          <Styled.Grid item xs={12} sm={12} md={6}>
            <Styled.Title>
              <Typography variant="subtitle1" fontWeight="bold">
                Início e fim da turma:
              </Typography>
              <Typography variant="subtitle1" ml={1}>
                {formatDate(startDate || detailsDash?.class_start_date)} -{' '}
                {formatDate(endDate || detailsDash?.class_end_date)}
              </Typography>
            </Styled.Title>
            <Styled.Title>
              <Typography variant="subtitle1" fontWeight="bold">
                Turno:
              </Typography>
              <Typography variant="subtitle1" ml={1}>
                {detailsDash?.shift}
              </Typography>
            </Styled.Title>
            <Styled.Title>
              <Typography variant="subtitle1" fontWeight="bold">
                Dias letivos (período):
              </Typography>
              <Typography variant="subtitle1" ml={1}>
                {detailsDash?.school_day_period}
              </Typography>
            </Styled.Title>
          </Styled.Grid>
          <Styled.Grid isRow item xs={12} sm={12} md={12}>
            <Styled.ContainerCards>
              {getCardsNumber({ detailsDash, theme }).map((cardData) => (
                <CardNumber
                  {...cardData}
                  onClickCard={() => {
                    handleFilterListByStatus({
                      detailsDash,
                      setListStudents,
                      filteredBy: cardData.title
                    })
                  }}
                />
              ))}
            </Styled.ContainerCards>
          </Styled.Grid>
          <Styled.Grid item xs={12} sm={12} md={12}>
            {listStudents && !IsEmpty(listStudents) && (
              <TableDashClass
                items={items}
                filter={filter}
                values={values}
                setPage={setPage}
                count={totalItems}
                classId={id_turma}
                setSnack={setSnack}
                setFilter={setFilter}
                totalPage={totalPage}
                classData={state?.row}
                pageActual={pageActual}
                setListEmail={setListEmail}
                setIsOpenModalEmail={setIsOpenModalEmail}
              />
            )}
          </Styled.Grid>
          <ModalEmail
            open={isOpenModalEmail}
            handleClose={() => setIsOpenModalEmail(false)}
            onSubmit={(e) => {
              onSubmitEmail({
                values: e,
                setSnack,
                listEmail,
                setLoadingVisibility,
                setIsOpenModalEmail
              })
            }}
            title="Enviar E-mail"
            message="Preencha os campos abaixo para enviar um e-mail ao destinatário:"
            setSnack={setSnack}
            email={listEmail.join('; ')}
          />
        </Styled.Grid>
      }
    />
  )
}
export default DashboardClass
