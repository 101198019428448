import { useSavedDataFromUrl, useStore } from 'store/GlobalContext'
import { ROUTE, ROLES } from 'services/constants'

const useMainRoute = () => {
  const { user } = useStore()
  const { route } = useSavedDataFromUrl()

  const roles = {
    [ROLES.PROFESSOR.name]: ROUTE.PROFESSOR_SCHOOL_CLASSES,
    [ROLES.STUDENT.name]: ROUTE.STUDENT_HUB,
    [ROLES.ADMIN.name]: route || ROUTE.ADMIN,
    [ROLES.ACADEMIC.name]: route || ROUTE.ADMIN
  }

  const url = roles[user?.role] || '/'

  return { roles, url }
}
export default useMainRoute
