import { httpGet, httpPost } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getAccesList = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ACCESS_USERS.ACCESS}?${convertToParams(params)}`
  )

  return response
}

export const getSelectProfile = async () => {
  const response = await httpGet(`${ENDPOINT.ACCESS_USERS.ACCESS}/user-nivel`)

  return response
}

export const getSelectSector = async () => {
  const response = await httpGet(`${ENDPOINT.ACCESS_USERS.ACCESS}/user-sector`)

  return response
}

export const newUserAccess = async (payload) => {
  const response = await httpPost(`${ENDPOINT.ACCESS_USERS.ACCESS}/user`, {
    ...payload
  })

  return response
}
