import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getContractsTypes = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.CONTRACTS_TYPES.BASE_CONTRACTS_TYPES}?${convertToParams(params)}`
  )
  return response
}

export const getDetailsDeclaration = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.CONTRACTS_TYPES.BASE_CONTRACTS_TYPES}/${id}`
  )

  return response
}

export const postContractsTypes = async (params) => {
  const response = await httpPost(`${ENDPOINT.CONTRACTS_TYPES.BASE_CONTRACTS_TYPES}`, {
    ...params
  })
  return response
}

export const editContractsTypes = async (params, id) => {
  const response = await httpPut(`${ENDPOINT.CONTRACTS_TYPES.BASE_CONTRACTS_TYPES}/${id}`, params)

  return response
}

export const editStatusContractsTypes = async (params) => {
  const response = await httpPut(`${ENDPOINT.CONTRACTS_TYPES.BASE_CONTRACTS_TYPES}/change-status
  `, params)

  return response
}
