import { styled, Button as MuiButton } from '@mui/material'

const Button = styled(MuiButton)(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor || '#ccc',
  borderRadius: '12px',
  width: '100%',
  height: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export { Button }
