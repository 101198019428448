import { lazy, useEffect, useState } from 'react'
import { Form as FormikForm, Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { usePaginationBasic, useSnack } from 'services/hooks'
import {
  IsEmpty,
  exportSpreadsheetByXLSX,
  formatDateEnv,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import { getContractsStatus, getContractsStatusXLSX } from 'services/api/admin'
import {
  defaultPage,
  nameAccordion,
  MESSAGE,
  defaultItemsPerPage,
  countDefault,
  SCHOOL_CLASS
} from './constants'
import schema from './schema'
import { initialValue } from './config'
import { useStoreContracts } from '../Store/useStoreContracts'

const Form = lazy(() => import('./components/Form'))
const Table = lazy(() => import('./components/Table'))

const ListContracts = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const snackProps = useSnack()
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [contractsList, setContractsList] = useState([])
  const [summary, setSummary] = useState({
    awaitingSignature: 0,
    signed: 0,
    awaitingAccess: 0
  })
  const [statusFilter, setStatusFilter] = useState(null)

  const isSearchParam = location?.state?.savedSearch || false

  const { formValues, setFormValues, resetFormValues } = useStoreContracts()

  const { page, totalPage, items, setPage, handleSetPage } = usePaginationBasic(
    contractsList,
    filter,
    count
  )
  const { setSnack } = snackProps

  const requestFilters = (values) => ({
    ...(values.searchType &&
      values.searchBy && { [values.searchType]: values.searchBy }),
    ...(values.unit && { unit: values.unit }),
    ...(values.status && { status: values.status }),
    begin_at: formatDateEnv(values.beginAt),
    end_at: formatDateEnv(values.endAt)
  })

  const onSubmit = async (values) => {
    setSnack('', '')
    setLoading(true)
    const paginationData = {
      limit: filter,
      page: page || defaultPage
    }

    const params = {
      ...paginationData,
      ...requestFilters(values)
    }
    const { data: response, error, status } = await getContractsStatus(params)

    setLoading(false)
    if (error) {
      setNotSearch(true)
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListContractStatus
      })
      return
    }

    const {
      awaiting_signature: awaitingSignature,
      signed,
      awaiting_access: awaitingAccess,
      data
    } = response
    if (!values.status)
      setSummary({ awaitingSignature, signed, awaitingAccess })
    setCount(response?.count)
    setContractsList(data)
    setNotSearch(false)
    setFormValues(values)
    setExpanded(false)
  }

  const cancelAutomaticSearch = () => {
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  const onClear = () => {
    setFormValues(null)
    resetFormValues()
    setContractsList([])
    setNotSearch(true)
    cancelAutomaticSearch()
  }

  useEffect(
    () => formValues?.beginAt && formValues?.endAt && onSubmit(formValues),
    [page, filter]
  )

  useEffect(() => {
    if (isSearchParam && formValues?.beginAt && formValues?.endAt) {
      return onSubmit(formValues)
    }

    resetFormValues()
    cancelAutomaticSearch()
    return setContractsList([])
  }, [isSearchParam])

  const initialState = mergeInitialValues(
    initialValue,
    isSearchParam && formValues
  )

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH_PERFOMED
    : MESSAGE.NOT_FOUND_SEARCH

  const onStatusFilter = async (status) => {
    setStatusFilter((state) => {
      const newStatus = state === status ? null : status
      onSubmit({ ...formValues, status: newStatus })
      return newStatus
    })
  }

  const onFilterAwaitingSignature = async () =>
    onStatusFilter('awaiting_signature')

  const onFilterSigned = async () => onStatusFilter('signed')

  const onFilterAwaitingAccess = async () => onStatusFilter('awaiting_access')

  const onExportReport = async (values) => {
    setLoading(true)
    const {
      data: response,
      status,
      error
    } = await getContractsStatusXLSX(requestFilters(values))

    setLoading(false)
    if (error) {
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToDownloadListContractStatus
      })
      return
    }

    exportSpreadsheetByXLSX(response, 'Contratos.xlsx')
  }

  const onExportAwaitingSignature = async () =>
    onExportReport({ ...formValues, status: 'awaiting_signature' })

  const onExportSigned = async () =>
    onExportReport({ ...formValues, status: 'signed' })

  const onExportAwaitingAccess = async () =>
    onExportReport({ ...formValues, status: 'awaiting_access' })

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Secretaria - Contratos"
      snackProps={snackProps}
      nameAccordion={nameAccordion}
      loadingOpen={loading}
      listItems={contractsList}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      expanded={expanded}
      setExpanded={setExpanded}
      accordion={
        <Formik
          initialValues={initialState}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={schema}
        >
          {(props) => (
            <FormikForm
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                setLoading={setLoading}
                setCount={setCount}
                setFilter={setFilter}
                setPage={setPage}
                onClear={onClear}
                setSnack={setSnack}
                {...props}
              />
            </FormikForm>
          )}
        </Formik>
      }
    >
      {contractsList && !IsEmpty(contractsList) && (
        <Table
          rows={items}
          filter={filter}
          setFilter={setFilter}
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          count={count}
          handleSetPage={handleSetPage}
          setLoading={setLoading}
          setSnack={setSnack}
          awaitingSignature={summary.awaitingSignature}
          signed={summary.signed}
          awaitingAccess={summary.awaitingAccess}
          onFilterAwaitingSignature={onFilterAwaitingSignature}
          onFilterSigned={onFilterSigned}
          onFilterAwaitingAccess={onFilterAwaitingAccess}
          onExportAwaitingSignature={onExportAwaitingSignature}
          onExportSigned={onExportSigned}
          onExportAwaitingAccess={onExportAwaitingAccess}
          statusFilter={statusFilter}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListContracts
