import { useFormik } from 'formik'
import { Grid, Typography } from '@mui/material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { useSnack } from 'services/hooks'
import { updateErrorMessage } from 'services/helpers'
import { DatePicker } from 'components/atoms'
import { ButtonFooterV2, Select } from 'components/molecules'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { getAtaNovoTec, openPartialReportPDF } from '../../services'
import { useClassDetailsContext } from '../../context'
import { getBimesterMap, getBimesterPeriodText } from './helpers'
import { initialValues, optionTypeFile } from './constants'
import getSchema from './schema'
import * as Styled from './style'

export function FormReportPartialLikeNovotec({ handleClose }) {
  const { classId, unitId } = useClassDetailsContext()
  const { setLoading } = useLoading()
  const { setSnack } = useSnack()
  const isNovotecFrom2024 = true
  const submit = (values) => {
    setLoading(true)

    if (isNovotecFrom2024) {
      return openPartialReportPDF({
        classId,
        endDate: values.endDate,
        startDate: values.startDate,
        onError: ({ error, status }) => {
          setLoading(false)
          updateErrorMessage({
            setSnack,
            error,
            status,
            feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToPartialReport
          })
        },
        onFinish: () => setLoading(false)
      })
    }

    return getAtaNovoTec({
      setSnack,
      setLoading,
      values,
      id: classId
    })
  }

  const formki = useFormik({
    initialValues,
    onSubmit: (values) => submit(values),
    enableReinitialize: true,
    validationSchema: getSchema({ isNovotecFrom2024, unit: unitId })
  })

  const periodBimester = getBimesterMap(unitId)
  return (
    <form noValidate autoComplete="off">
      <Styled.Grid>
        <Grid container spacing={{ xs: 0, sm: 2 }}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Escolha o período para as ata parcial que você deseja gerar:
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <DatePicker
              fullWidth
              id="startDate"
              label={isNovotecFrom2024 ? 'De' : 'De (opcional)'}
              value={formki.values?.startDate || null}
              onChange={(newValue) => {
                formki.setFieldValue('startDate', newValue)
              }}
              error={formki.errors?.startDate}
              helperText={formki.errors?.startDate}
            />
          </Grid>

          <Grid item xs={4}>
            <DatePicker
              fullWidth
              id="endDate"
              label={isNovotecFrom2024 ? 'Até' : 'Até (opcional)'}
              value={formki.values?.endDate || null}
              onChange={(newValue) => {
                formki.setFieldValue('endDate', newValue)
              }}
              error={formki.errors?.endDate}
              helperText={formki.errors?.endDate}
            />
          </Grid>

          <Grid item xs={3}>
            <Select
              id="typeFile"
              label="Formato"
              value={formki.values?.typeFile || 'pdf'}
              optionsList={optionTypeFile}
              onChange={(e) => formki.setFieldValue('typeFile', e.target.value)}
            />
          </Grid>
          {isNovotecFrom2024 && (
            <Grid item xs={12}>
              <Typography fontWeight={600} variant="body2">
                É necessário selecionar as datas exatas de início e fim dos
                bimestres:
              </Typography>
              {periodBimester &&
                Object?.entries(periodBimester)?.map(([year, bimesters]) =>
                  Object.entries(bimesters).map(
                    ([bimester, { begin, end }]) => (
                      <Typography variant="body2">
                        {getBimesterPeriodText({
                          bimester,
                          begin,
                          end,
                          year
                        })}
                      </Typography>
                    )
                  )
                )}
            </Grid>
          )}
        </Grid>

        <Styled.Box>
          <ButtonFooterV2
            labelClose="Cancelar"
            labelConfirm="Confirmar"
            size="medium"
            onClickClose={handleClose}
            onClickConfirm={(e) => formki.handleSubmit(e)}
            disabledConfirm={Object.keys(formki.errors).length}
          />
        </Styled.Box>
      </Styled.Grid>
    </form>
  )
}
