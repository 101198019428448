import { Clear } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { InputSwitch } from 'components/atoms'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import { isDisabledButtonList } from '../../../Declarations/helpers'

const Form = ({ unitys, values, setFieldValue, listProfile, listSector }) => (
  <>
    <Grid item xs={12} sm={12} md={2}>
      <TextField
        fullWidth
        name="name"
        isMargin="0"
        value={values?.name}
        label="Nome"
        onChange={(e) => setFieldValue('name', e.target.value)}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={2}>
      <Select
        id="profile"
        name="profile"
        optionsList={listProfile}
        label="Perfil"
        value={values?.profile || ''}
        onChange={(e) => setFieldValue('profile', e.target.value)}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={2}>
      <Select
        id="sector"
        name="sector"
        optionsList={listSector}
        label="Setor"
        value={values?.sector || ''}
        onChange={(e) => setFieldValue('sector', e.target.value)}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={2}>
      <Select
        id="unit"
        name="unit"
        optionsList={unitys}
        label="Unidade"
        value={values?.unit || ''}
        onChange={(e) => setFieldValue('unit', e.target.value)}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={1}>
      <InputSwitch
        label="Inativos"
        checked={values.inactive}
        onClick={(e) => setFieldValue('inactive', e.target.checked)}
      />
    </Grid>
  </>
)

export default Form
