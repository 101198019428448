import {
  getSelectProfile,
  getSelectSector
} from 'services/api/admin/system/AccessUsers'
import { updateErrorMessage } from 'services/helpers'

const selectProfile = async ({ setListProfile, setSnack }) => {
  setSnack('')

  const { data, error, status } = await getSelectProfile()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: ''
    })
  }
  const profile = data

  return setListProfile(profile)
}

const selectSector = async ({ setListSector, setSnack }) => {
  setSnack('')

  const { data, error, status } = await getSelectSector()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: ''
    })
  }
  const sector = data

  return setListSector(sector)
}

const isDisabledButton = ({ name, profile, sector, unit }) => {
  const enabled = name && profile && sector && unit
  return !enabled
}

export { selectProfile, selectSector, isDisabledButton }
