import FEEDBACK_SNACK from 'feedBackSnack'
import {
  deleleDocumentHistory,
  getStudentInfo,
  getStudentInfoHistory
} from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'

const deleteDocumentHistory = async ({ setSnack, setLoading, id, setIsDeleteDocumentHistory}) => {
  setSnack('')
  setLoading(true)
  const { data, error, status } = await deleleDocumentHistory(id)

  setLoading(false)
  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfoHistory
    })
  }
  setIsDeleteDocumentHistory(true)

}

const getStudentHistory = async ({
  setSnack,
  setLoading,
  setStudentHistory,
  setNotFoundSearch,
  register
}) => {
  setSnack('')
  setLoading(true)
  const { data, error, status } = await getStudentInfoHistory(register)
  setLoading(false)
  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfoHistory
    })
  }
  setNotFoundSearch(error)
  setStudentHistory(data)
}

const loadStudentInfo = async ({
  setSnack,
  setLoading,
  setStudentInfo,
  register
}) => {
  setSnack('')
  setLoading(true)

  const { data: response, error, status } = await getStudentInfo(register)

  setLoading(false)

  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfo
    })
  }
  setStudentInfo(response)
}

export { deleteDocumentHistory, getStudentHistory, loadStudentInfo }
