import { useEffect, useState } from 'react'
import { timeZone } from 'services/helpers'

const useCountDown = (date) => {
  const [days, setDays] = useState()
  const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  const [seconds, setSeconds] = useState()

  const countDown = () => {
    const deadline = new Date(date).getTime()

    let now = new Date()
    now.setDate(now.getDate() - 1)
    now.setHours(now.getHours() - timeZone)
    now = now.getTime()

    const interval = deadline - now

    const second = 1000
    const minute = second * 60
    const hour = minute * 60
    const day = hour * 24

    const dayNumber =
      Math.floor(interval / day) > 99 ? 99 : Math.floor(interval / day)
    const hourNumber = Math.floor((interval % day) / hour)
    const minuteNumber = Math.floor((interval % hour) / minute)
    const secondNumber = Math.floor((interval % minute) / second)

    setDays(dayNumber < 0 ? 0 : dayNumber)
    setHours(hourNumber < 0 ? 0 : hourNumber)
    setMinutes(minuteNumber < 0 ? 0 : minuteNumber)
    setSeconds(secondNumber < 0 ? 0 : secondNumber)
  }

  useEffect(() => {
    const run = setInterval(countDown, 1000)
    return () => clearInterval(run)
  }, [date])

  return [days, hours, minutes, seconds]
}

export default useCountDown
