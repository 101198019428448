import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { Clear } from '@mui/icons-material'
import { useMakeService, usePaginationBasic, useSnack } from 'services/hooks'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ButtonFooterV2 } from 'components/molecules'
import { ERROR, ROUTE } from 'services/constants'
import { getErrorMessage, updateErrorMessage } from 'services/helpers'
import { getAccesList } from 'services/api/admin/system/AccessUsers'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { cleanObject } from 'services/helpers/cleanObject'
import Form from './components/Form'
import Table from './components/Table'
import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  MESSAGE,
  defaultTotalPage,
  initialState
} from './constants'
import { selectProfile, selectSector } from './services'
import { isDisabledButtonList } from '../Declarations/helpers'
import * as Styled from './style'

const Declarations = () => {
  const [notSearch, setNotSearch] = useState(true)
  const [loading, setLoading] = useState(false)
  const [listAccess, setListAccess] = useState([])
  const [filter, setFilter] = useState(DEFAULT_ITEMS_PER_PAGE)
  const [count, setCount] = useState(null)
  const [listProfile, setListProfile] = useState([])
  const [listSector, setListSector] = useState([])
  const snackProps = useSnack()
  const { setSnack } = snackProps

  const { page, totalPage, items, setPage, handleSetPage } = usePaginationBasic(
    listAccess,
    filter,
    count
  )

  const paramsMakeService = {
    isUnits: true,
    setSnack
  }
  const { unitys } = useMakeService(paramsMakeService)

  const initialValues = initialState

  const handleSearch = async (values) => {
    setLoading(true)

    const params = cleanObject({
      name: values?.name,
      unit: values?.unit,
      is_inactive: values?.is_inactive,
      profile_type_id: values?.profile_type_id,
      sector_type_id: values?.sector_type_id,
      limit: filter || defaultTotalPage,
      page: page || DEFAULT_PAGE
    })

    const { data, error, status } = await getAccesList(params)

    setLoading(false)
    setCount(data?.count)
    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListAccess
      })
    }

    if (error) {
      const message = getErrorMessage(error, status)
      return setSnack(message, ERROR)
    }

    setNotSearch(false)

    return setListAccess(data?.data)
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      handleSearch(values)
    }
  })

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  useEffect(() => {
    selectProfile({ setListProfile, setSnack })
    selectSector({ setListSector, setSnack })
  }, [])

  return (
    <>
      <TemplateDefaultFormAndResult
        snackProps={snackProps}
        listItems={listAccess}
        labelButtonHeader="Nova Acesso"
        loadingVisibility={loading}
        linkHeader={ROUTE.CREATE_ACCESS_USER}
        iconInformative={messageInformative.icon}
        titleHeader="<b>Sistema - </b> Acessos"
        messageInformative={messageInformative.text}
        classNameHeader={TAG_MANAGER.sistema_btn_cria_novaDeclaracao}
        tagmanagerModal={TAG_MANAGER.sistema_btn_cria_novaDeclaracao}
        formHeader={
          <Styled.Form
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
              <Form
                setSnack={setSnack}
                values={formik.values}
                unitys={unitys}
                setFieldValue={formik.setFieldValue}
                listProfile={listProfile}
                listSector={listSector}
              />
              <Styled.Grid item xs={12} sm={12} md={3}>
                <ButtonFooterV2
                  size="medium"
                  labelClose="Limpar"
                  labelConfirm="Buscar"
                  startIcon={<Clear />}
                  disabledClose={isDisabledButtonList(formik.values)}
                  // onClickClose={() => handleClear()} --- sera adicionado na proxima PR
                />
              </Styled.Grid>
            </Styled.GridContainer>
          </Styled.Form>
        }
      />

      {listAccess.length > 0 && (
        <Table rows={items} setSnack={setSnack} setLoading={setLoading} />
      )}
    </>
  )
}

export default Declarations
