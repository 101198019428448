import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { PageTitle } from 'components/atoms'
import { useMakeService, useSnack } from 'services/hooks'
import { ButtonFooterV2, Snackbar } from 'components/molecules'
import { ROUTE, SUCCESS } from 'services/constants'
import { cleanObject } from 'services/helpers/cleanObject'
import { editDeclarations } from 'services/api/admin/system/Declarations'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import TAG_MANAGER from 'tagManager'
import { initialState } from '../Forms/constants'
import { FormDeclarationContent, FormDeclarationData } from '../Forms'
import { breadcrumbsItems } from './constants'
import { setValuesEdit } from './services'
import * as Styled from './style'
import { isDisabledButton } from '../../helpers'

const EditDeclaretion = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [expanded, setExpanded] = useState('')
  const [loading, setLoading] = useState(false)

  const [value, setValue] = useState('1')

  const snackProps = useSnack()
  const { snack, setSnack, snackOpen } = snackProps

  const paramsMakeService = {
    isLevel: true,
    setSnack
  }

  const { level } = useMakeService(paramsMakeService)

  const editDeclaretion = async (values) => {
    setLoading(true)
    setSnack('', '')
    const id = location?.state?.row?.id

    const params = cleanObject({
      name: values.name,
      body: values.body,
      level: values.level,
      header: values.header,
      footer: values.footer,
      online: values.online,
      signature: values.signature,
      check_date: values.check_date,
      sending_required: values.sending_required,
      face_to_face: values.face_to_face
    })

    const { error, status } = await editDeclarations(id, params)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditDeclaretion
      })
    }

    setLoading(false)
    setSnack('Declaração atualizada com sucesso.', SUCCESS)

    return setTimeout(
      () =>
        navigate(ROUTE.LIST_DECLARATIONS, {
          state: { savedSearch: true }
        }),
      1500
    )
  }

  const handleChange = (_e, newValue) => {
    setValue(newValue)
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: (values) => {
      editDeclaretion(values)
    }
  })

  useEffect(() => {
    setValuesEdit(location?.state?.row, formik.setValues)
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      classNameHeader="goBack"
      breadcrumbsNotLink={breadcrumbsItems(formik.values.name)}
      onClickButton={() => {
        navigate(ROUTE.LIST_DECLARATIONS, {
          state: { savedSearch: true }
        })
      }}
      content={
        <>
          <Styled.Grid item xs={12} sm={12} md={12} marginBottom={2}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Dados" value="1" />
                <Tab label="Conteúdo" value="2" />
              </Tabs>
            </Box>
          </Styled.Grid>

          <Styled.Form
            onSubmit={formik.handleSubmit}
            noValidate
            autoComplete="off"
          >
            {value === '1' && (
              <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <PageTitle padding="0" title="Dados da Declaração" />
                </Grid>
                <FormDeclarationData
                  level={level}
                  setSnack={setSnack}
                  values={formik.values}
                  setFieldValue={formik.setFieldValue}
                />
              </Styled.GridContainer>
            )}

            {value === '2' && (
              <Styled.GridContainer
                alignItems="start !important"
                container
                spacing={{ xs: 0, sm: 2 }}
              >
                <Grid item xs={12}>
                  <Styled.Typography variant="body1">
                    Utilize as TAGs ao lado para incluir dados variáveis no
                    texto
                  </Styled.Typography>
                </Grid>
                <FormDeclarationContent
                  level={level}
                  setSnack={setSnack}
                  expanded={expanded}
                  values={formik.values}
                  setExpanded={setExpanded}
                  setFieldValue={formik.setFieldValue}
                />
              </Styled.GridContainer>
            )}
            <Styled.Grid end marginTop={2} item xs={12} sm={12} md={12}>
              <ButtonFooterV2
                size="medium"
                labelClose="Cancelar"
                labelConfirm="Salvar"
                disabledConfirm={isDisabledButton(formik.values) || loading}
                className={TAG_MANAGER.sistema_btn_salva_novaDeclaracao}
                onClickClose={() => {
                  navigate(ROUTE.LIST_DECLARATIONS, {
                    state: { savedSearch: true }
                  })
                }}
              />
            </Styled.Grid>
          </Styled.Form>
        </>
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default EditDeclaretion
