export const numberSelected = 0
export const indexDefault = -1
export const isSelected = 1
export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultName = 'name'
export const typeAll = 'Tudo'
export const defaultTotalPage = 1

const headCellsPending = [
  {
    id: 'name',
    label: 'Contrato'
  },
  {
    id: 'initialtext',
    label: 'Texto inicial'
  },
  {
    id: 'electronicsignature',
    label: 'Assinatura eletrônica'
  },
]

export { headCellsPending }
