export const finalReportMock = {
  companyInfo: [
    'ESSA Educação Profissional S.A CNPJ: 08.690.642/0001-71',
    'Rua Cassuarinas, 108 - Vila Parque Jabaquara - São Paulo/SP CEP: 04321-100 - Unidade (SP) NOVOTEC',
    'Portaria do Dirigente Regional Sul 1 - de 05/06/2007 - Publicada no DOE de 06/06/2007- Página 28'
  ],
  classInfo: {
    classStartDate: '2022-02-01',
    classEndDate: '2024-12-22',
    course: 'Técnico em Administração',
    classroom: 'João Vassão_NOV22MADMRE1',
    unit: '(SP) NOVOTEC',
    pole: 'João Adôrno Vassão EE',
    minimumGrade: 5,
    observation:
      'Código da turma:  / Número da classe: 270307226 / Código da escola: 34939',
    directorUnitSignatureImage:
      'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/171286915283863bcf5c7d7744401b00e8408151db977.jpeg',
    directorUnitName: 'Nilton Amato Junior',
    directorUnitIdentifier: 'RG 16.663.443-8',
    directorUnitRole: 'Diretor',
    secretaryUnitSignatureImage:
      'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/17128691527261db71f4b24bc4475abec182d0615b5f6.png',
    secretaryUnitName: 'Rafaela dos Santos Silva',
    secretaryUnitIdentifier: 'RG 39.149.472-7',
    secretaryUnitRole: 'Secretaria',
    unitCorporateReason: 'ESSA Educação Profissional S.A',
    unitAddress: 'Rua Cassuarinas',
    unitDistrict: 'Vila Parque Jabaquara',
    unitAddressNumber: '108',
    unitCity: 'São Paulo',
    unitUf: 'SP',
    unitResolution:
      'Portaria do Dirigente Regional Sul 1 - de 05/06/2007 - Publicada no DOE de 06/06/2007- Página 28',
    unitCNPJ: '08.690.642/0001-71',
    unitCEP: '04321-100',
    directorPoleSignatureImage: null,
    directorPoleName: null,
    directorPoleIdentifier: null,
    directorPoleRole: null,
    secretaryPoleSignatureImage: null,
    secretaryPoleName: null,
    secretaryPoleIdentifier: null,
    secretaryPoleRole: null,
    poleCorporateReason: 'João Adôrno Vassão EE',
    poleAddress: 'Rua Visconde do Rio Branco',
    poleDistrict: 'Vila Industrial (Campinas)',
    poleAddressNumber: '205',
    poleCity: 'JUQUIÁ',
    poleUf: 'SP',
    poleResolution: '',
    poleCNPJ: '',
    poleCEP: '11800-000',
    classTime: 45,
    companyId: 81,
    module: 0,
    shift: 'Semanal - Manhã',
    level: 'Curso Técnico',
    frequencyType: 'DISCIPLINA',
    minimumFrequency: 25,
    sumModule: 1,
    yearGrade: 'NT22ADM1RE',
    numberOfClasses: null,
    bimester: '1',
    bimesterYear: 2024,
    signatures: [
      {
        name: 'Nilton Amato Junior',
        role: 'Diretor',
        identifier: 'RG 16.663.443-8',
        image:
          'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/171286915283863bcf5c7d7744401b00e8408151db977.jpeg'
      },
      {
        name: 'Rafaela dos Santos Silva',
        role: 'Secretaria',
        identifier: 'RG 39.149.472-7',
        image:
          'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/17128691527261db71f4b24bc4475abec182d0615b5f6.png'
      }
    ]
  },
  subjects: [
    {
      subjectId: 443212,
      name: 'Administração da Produção e Serviços',
      workload: 90,
      beginAt: '2024-02-16T00:00:00.000Z',
      endAt: '2024-12-24T00:00:00.000Z'
    },
    {
      subjectId: 443213,
      name: 'Administração de Recursos Humanos',
      workload: 90,
      beginAt: '2024-02-16T00:00:00.000Z',
      endAt: '2024-12-24T00:00:00.000Z'
    },
    {
      subjectId: 443214,
      name: 'Administração Financeira e Orçamentária',
      workload: 90,
      beginAt: '2024-02-16T00:00:00.000Z',
      endAt: '2024-12-24T00:00:00.000Z'
    },
    {
      subjectId: 443215,
      name: 'Desenvolvimento de Modelos de Negócios',
      workload: 90,
      beginAt: '2024-02-16T00:00:00.000Z',
      endAt: '2024-12-24T00:00:00.000Z'
    },
    {
      subjectId: 443216,
      name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
      workload: 90,
      beginAt: '2024-02-16T00:00:00.000Z',
      endAt: '2024-12-24T00:00:00.000Z'
    },
    {
      subjectId: 443217,
      name: 'Processos Logísticos Empresariais',
      workload: 90,
      beginAt: '2024-02-16T00:00:00.000Z',
      endAt: '2024-12-24T00:00:00.000Z'
    },
    {
      subjectId: 443218,
      name: 'Tecnologia da Informação em Administração',
      workload: 90,
      beginAt: '2024-02-16T00:00:00.000Z',
      endAt: '2024-12-24T00:00:00.000Z'
    }
  ],
  students: [
    {
      student: {
        enrollment: 709192,
        name: 'ANA CLARA DA SILVA CUNHA',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 783966
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709192',
            name: 'ANA CLARA DA SILVA CUNHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783966,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 1.5,
            partialGrade: 5.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 5.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 37,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709192',
            name: 'ANA CLARA DA SILVA CUNHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783966,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709192',
            name: 'ANA CLARA DA SILVA CUNHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783966,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 3,
            partialGrade: 7,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709192',
            name: 'ANA CLARA DA SILVA CUNHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783966,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709192',
            name: 'ANA CLARA DA SILVA CUNHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783966,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 8,
            activitiesGrade: null,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709192',
            name: 'ANA CLARA DA SILVA CUNHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783966,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 3,
            partialGrade: 7,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709192',
            name: 'ANA CLARA DA SILVA CUNHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783966,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 1.5,
            partialGrade: 5.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 5.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709191,
        name: 'ANA ROSA DA CRUZ COUTINHO',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 783967
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709191',
            name: 'ANA ROSA DA CRUZ COUTINHO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783967,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 2,
            justifiedAbsence: 0,
            presence: 35,
            absenceInPercentage: 1.6666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709191',
            name: 'ANA ROSA DA CRUZ COUTINHO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783967,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709191',
            name: 'ANA ROSA DA CRUZ COUTINHO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783967,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 2,
            justifiedAbsence: 0,
            presence: 23,
            absenceInPercentage: 1.6666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709191',
            name: 'ANA ROSA DA CRUZ COUTINHO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783967,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709191',
            name: 'ANA ROSA DA CRUZ COUTINHO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783967,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 8,
            activitiesGrade: null,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709191',
            name: 'ANA ROSA DA CRUZ COUTINHO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783967,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709191',
            name: 'ANA ROSA DA CRUZ COUTINHO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783967,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709193,
        name: 'ANNA LARA CAMARGO PONTES',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 783968
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709193',
            name: 'ANNA LARA CAMARGO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783968,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 37,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709193',
            name: 'ANNA LARA CAMARGO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783968,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709193',
            name: 'ANNA LARA CAMARGO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783968,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709193',
            name: 'ANNA LARA CAMARGO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783968,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709193',
            name: 'ANNA LARA CAMARGO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783968,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709193',
            name: 'ANNA LARA CAMARGO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783968,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709193',
            name: 'ANNA LARA CAMARGO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783968,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709194,
        name: 'CAROL RIBEIRO DE PAULO LARAGNOIT',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 783969
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709194',
            name: 'CAROL RIBEIRO DE PAULO LARAGNOIT',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783969,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 7,
            justifiedAbsence: 0,
            presence: 30,
            absenceInPercentage: 5.833333333333333,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709194',
            name: 'CAROL RIBEIRO DE PAULO LARAGNOIT',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783969,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709194',
            name: 'CAROL RIBEIRO DE PAULO LARAGNOIT',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783969,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 10,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 8.333333333333332,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709194',
            name: 'CAROL RIBEIRO DE PAULO LARAGNOIT',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783969,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709194',
            name: 'CAROL RIBEIRO DE PAULO LARAGNOIT',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783969,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709194',
            name: 'CAROL RIBEIRO DE PAULO LARAGNOIT',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783969,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709194',
            name: 'CAROL RIBEIRO DE PAULO LARAGNOIT',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783969,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709195,
        name: 'ENZO FROIS GARCIA DE MATOS',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 783970
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709195',
            name: 'ENZO FROIS GARCIA DE MATOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783970,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 31,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709195',
            name: 'ENZO FROIS GARCIA DE MATOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783970,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709195',
            name: 'ENZO FROIS GARCIA DE MATOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783970,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 2,
            justifiedAbsence: 0,
            presence: 23,
            absenceInPercentage: 1.6666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709195',
            name: 'ENZO FROIS GARCIA DE MATOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783970,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709195',
            name: 'ENZO FROIS GARCIA DE MATOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783970,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 8,
            activitiesGrade: null,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709195',
            name: 'ENZO FROIS GARCIA DE MATOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783970,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709195',
            name: 'ENZO FROIS GARCIA DE MATOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783970,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709196,
        name: 'EVELIN ROSA CUBAS',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 783971
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709196',
            name: 'EVELIN ROSA CUBAS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783971,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 0,
            activitiesGrade: null,
            partialGrade: 0,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 37,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 30.833333333333336,
            maximumAbsence: 30,
            approvedByFrequency: false
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709196',
            name: 'EVELIN ROSA CUBAS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783971,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 0,
            activitiesGrade: null,
            partialGrade: 0,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 21,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 17.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709196',
            name: 'EVELIN ROSA CUBAS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783971,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 0,
            activitiesGrade: null,
            partialGrade: 0,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 25,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 20.833333333333336,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709196',
            name: 'EVELIN ROSA CUBAS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783971,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 0,
            activitiesGrade: null,
            partialGrade: 0,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 21,
            justifiedAbsence: 0,
            presence: 3,
            absenceInPercentage: 17.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709196',
            name: 'EVELIN ROSA CUBAS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783971,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 0,
            activitiesGrade: null,
            partialGrade: 0,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 27,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 22.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709196',
            name: 'EVELIN ROSA CUBAS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783971,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 0,
            activitiesGrade: null,
            partialGrade: 0,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 21,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 17.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709196',
            name: 'EVELIN ROSA CUBAS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783971,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 0,
            activitiesGrade: null,
            partialGrade: 0,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 24,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 20,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 710725,
        name: 'EVELYN PINHEIRO PONTES',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 783972
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710725',
            name: 'EVELYN PINHEIRO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783972,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 5,
            justifiedAbsence: 0,
            presence: 32,
            absenceInPercentage: 4.166666666666666,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710725',
            name: 'EVELYN PINHEIRO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783972,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710725',
            name: 'EVELYN PINHEIRO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783972,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 4,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 3.3333333333333335,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710725',
            name: 'EVELYN PINHEIRO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783972,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710725',
            name: 'EVELYN PINHEIRO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783972,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 6,
            activitiesGrade: null,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710725',
            name: 'EVELYN PINHEIRO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783972,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710725',
            name: 'EVELYN PINHEIRO PONTES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783972,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709197,
        name: 'GABRIEL GUERRA MIGUEL',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 783973
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709197',
            name: 'GABRIEL GUERRA MIGUEL',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783973,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709197',
            name: 'GABRIEL GUERRA MIGUEL',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783973,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709197',
            name: 'GABRIEL GUERRA MIGUEL',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783973,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709197',
            name: 'GABRIEL GUERRA MIGUEL',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783973,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709197',
            name: 'GABRIEL GUERRA MIGUEL',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783973,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709197',
            name: 'GABRIEL GUERRA MIGUEL',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783973,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709197',
            name: 'GABRIEL GUERRA MIGUEL',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 783973,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709198,
        name: 'GABRIEL KAYU YADA MUNIZ',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 783974
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709198',
            name: 'GABRIEL KAYU YADA MUNIZ',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783974,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 34,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709198',
            name: 'GABRIEL KAYU YADA MUNIZ',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783974,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709198',
            name: 'GABRIEL KAYU YADA MUNIZ',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783974,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 1.5,
            partialGrade: 4.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 4.5,
            approvedByGrade: false
          },
          frequency: {
            absence: 2,
            justifiedAbsence: 0,
            presence: 23,
            absenceInPercentage: 1.6666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709198',
            name: 'GABRIEL KAYU YADA MUNIZ',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783974,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709198',
            name: 'GABRIEL KAYU YADA MUNIZ',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783974,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 6,
            activitiesGrade: null,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709198',
            name: 'GABRIEL KAYU YADA MUNIZ',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783974,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709198',
            name: 'GABRIEL KAYU YADA MUNIZ',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 783974,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 710729,
        name: 'GABRIELE VICTORIA SANCHES SILVA\t',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784018
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710729',
            name: 'GABRIELE VICTORIA SANCHES SILVA\t',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784018,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 36,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710729',
            name: 'GABRIELE VICTORIA SANCHES SILVA\t',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784018,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710729',
            name: 'GABRIELE VICTORIA SANCHES SILVA\t',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784018,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 25,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710729',
            name: 'GABRIELE VICTORIA SANCHES SILVA\t',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784018,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710729',
            name: 'GABRIELE VICTORIA SANCHES SILVA\t',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784018,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710729',
            name: 'GABRIELE VICTORIA SANCHES SILVA\t',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784018,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710729',
            name: 'GABRIELE VICTORIA SANCHES SILVA\t',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784018,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 3,
            partialGrade: 7,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709199,
        name: 'GABRIELLY MENDES DA SILVA',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 784020
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709199',
            name: 'GABRIELLY MENDES DA SILVA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784020,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709199',
            name: 'GABRIELLY MENDES DA SILVA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784020,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709199',
            name: 'GABRIELLY MENDES DA SILVA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784020,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709199',
            name: 'GABRIELLY MENDES DA SILVA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784020,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709199',
            name: 'GABRIELLY MENDES DA SILVA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784020,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709199',
            name: 'GABRIELLY MENDES DA SILVA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784020,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709199',
            name: 'GABRIELLY MENDES DA SILVA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784020,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709200,
        name: 'GUILHERME ZAPOTOSKI MENDES',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784021
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709200',
            name: 'GUILHERME ZAPOTOSKI MENDES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784021,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 4,
            justifiedAbsence: 0,
            presence: 33,
            absenceInPercentage: 3.3333333333333335,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709200',
            name: 'GUILHERME ZAPOTOSKI MENDES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784021,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709200',
            name: 'GUILHERME ZAPOTOSKI MENDES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784021,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 4,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 3.3333333333333335,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709200',
            name: 'GUILHERME ZAPOTOSKI MENDES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784021,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709200',
            name: 'GUILHERME ZAPOTOSKI MENDES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784021,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 6,
            activitiesGrade: null,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709200',
            name: 'GUILHERME ZAPOTOSKI MENDES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784021,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709200',
            name: 'GUILHERME ZAPOTOSKI MENDES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784021,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709201,
        name: 'JOANA KAROLYNE DE LIMA PAIXÃO',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784022
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709201',
            name: 'JOANA KAROLYNE DE LIMA PAIXÃO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784022,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 36,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709201',
            name: 'JOANA KAROLYNE DE LIMA PAIXÃO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784022,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709201',
            name: 'JOANA KAROLYNE DE LIMA PAIXÃO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784022,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 25,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709201',
            name: 'JOANA KAROLYNE DE LIMA PAIXÃO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784022,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709201',
            name: 'JOANA KAROLYNE DE LIMA PAIXÃO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784022,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709201',
            name: 'JOANA KAROLYNE DE LIMA PAIXÃO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784022,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709201',
            name: 'JOANA KAROLYNE DE LIMA PAIXÃO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784022,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 710738,
        name: 'JOVANI RODRIGUES PELLINSON',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 784023
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710738',
            name: 'JOVANI RODRIGUES PELLINSON',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784023,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710738',
            name: 'JOVANI RODRIGUES PELLINSON',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784023,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710738',
            name: 'JOVANI RODRIGUES PELLINSON',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784023,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710738',
            name: 'JOVANI RODRIGUES PELLINSON',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784023,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710738',
            name: 'JOVANI RODRIGUES PELLINSON',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784023,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710738',
            name: 'JOVANI RODRIGUES PELLINSON',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784023,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710738',
            name: 'JOVANI RODRIGUES PELLINSON',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784023,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709202,
        name: 'KARINNY VITÓRIA GONÇALVES NUNES',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784024
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709202',
            name: 'KARINNY VITÓRIA GONÇALVES NUNES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784024,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 36,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709202',
            name: 'KARINNY VITÓRIA GONÇALVES NUNES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784024,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709202',
            name: 'KARINNY VITÓRIA GONÇALVES NUNES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784024,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709202',
            name: 'KARINNY VITÓRIA GONÇALVES NUNES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784024,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709202',
            name: 'KARINNY VITÓRIA GONÇALVES NUNES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784024,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709202',
            name: 'KARINNY VITÓRIA GONÇALVES NUNES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784024,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709202',
            name: 'KARINNY VITÓRIA GONÇALVES NUNES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784024,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709203,
        name: 'KAYRA DAUT MORAES',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 784025
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709203',
            name: 'KAYRA DAUT MORAES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784025,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709203',
            name: 'KAYRA DAUT MORAES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784025,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709203',
            name: 'KAYRA DAUT MORAES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784025,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709203',
            name: 'KAYRA DAUT MORAES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784025,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709203',
            name: 'KAYRA DAUT MORAES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784025,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709203',
            name: 'KAYRA DAUT MORAES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784025,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709203',
            name: 'KAYRA DAUT MORAES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784025,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709204,
        name: 'KYOOSHI KOIKE FRANÇA',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 784026
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709204',
            name: 'KYOOSHI KOIKE FRANÇA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784026,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709204',
            name: 'KYOOSHI KOIKE FRANÇA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784026,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709204',
            name: 'KYOOSHI KOIKE FRANÇA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784026,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709204',
            name: 'KYOOSHI KOIKE FRANÇA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784026,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709204',
            name: 'KYOOSHI KOIKE FRANÇA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784026,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709204',
            name: 'KYOOSHI KOIKE FRANÇA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784026,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709204',
            name: 'KYOOSHI KOIKE FRANÇA',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784026,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709205,
        name: 'LEONARDO DA SILVA SANTOS CORDEIRO',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784027
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709205',
            name: 'LEONARDO DA SILVA SANTOS CORDEIRO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784027,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 2,
            justifiedAbsence: 0,
            presence: 35,
            absenceInPercentage: 1.6666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709205',
            name: 'LEONARDO DA SILVA SANTOS CORDEIRO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784027,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709205',
            name: 'LEONARDO DA SILVA SANTOS CORDEIRO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784027,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 22,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709205',
            name: 'LEONARDO DA SILVA SANTOS CORDEIRO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784027,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709205',
            name: 'LEONARDO DA SILVA SANTOS CORDEIRO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784027,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709205',
            name: 'LEONARDO DA SILVA SANTOS CORDEIRO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784027,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709205',
            name: 'LEONARDO DA SILVA SANTOS CORDEIRO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784027,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709206,
        name: 'LIANDRA DO CARMO ROCHA',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784028
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709206',
            name: 'LIANDRA DO CARMO ROCHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784028,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 4,
            justifiedAbsence: 0,
            presence: 33,
            absenceInPercentage: 3.3333333333333335,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709206',
            name: 'LIANDRA DO CARMO ROCHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784028,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709206',
            name: 'LIANDRA DO CARMO ROCHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784028,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 19,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709206',
            name: 'LIANDRA DO CARMO ROCHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784028,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709206',
            name: 'LIANDRA DO CARMO ROCHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784028,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 8,
            activitiesGrade: null,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709206',
            name: 'LIANDRA DO CARMO ROCHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784028,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709206',
            name: 'LIANDRA DO CARMO ROCHA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784028,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 3,
            partialGrade: 7,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 710748,
        name: 'MARCELA LAÍS MENDES JANUARIO',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784030
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710748',
            name: 'MARCELA LAÍS MENDES JANUARIO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784030,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 36,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710748',
            name: 'MARCELA LAÍS MENDES JANUARIO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784030,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710748',
            name: 'MARCELA LAÍS MENDES JANUARIO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784030,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710748',
            name: 'MARCELA LAÍS MENDES JANUARIO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784030,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710748',
            name: 'MARCELA LAÍS MENDES JANUARIO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784030,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710748',
            name: 'MARCELA LAÍS MENDES JANUARIO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784030,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '710748',
            name: 'MARCELA LAÍS MENDES JANUARIO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784030,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709207,
        name: 'MARCOS HENRIQUE DE OLIVEIRA FERNANDES',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 784031
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709207',
            name: 'MARCOS HENRIQUE DE OLIVEIRA FERNANDES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784031,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709207',
            name: 'MARCOS HENRIQUE DE OLIVEIRA FERNANDES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784031,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709207',
            name: 'MARCOS HENRIQUE DE OLIVEIRA FERNANDES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784031,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709207',
            name: 'MARCOS HENRIQUE DE OLIVEIRA FERNANDES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784031,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709207',
            name: 'MARCOS HENRIQUE DE OLIVEIRA FERNANDES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784031,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709207',
            name: 'MARCOS HENRIQUE DE OLIVEIRA FERNANDES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784031,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709207',
            name: 'MARCOS HENRIQUE DE OLIVEIRA FERNANDES',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784031,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709208,
        name: 'MARINA SANDES BARROS',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784033
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709208',
            name: 'MARINA SANDES BARROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784033,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 3,
            partialGrade: 7,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 31,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709208',
            name: 'MARINA SANDES BARROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784033,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709208',
            name: 'MARINA SANDES BARROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784033,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 7,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 5.833333333333333,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709208',
            name: 'MARINA SANDES BARROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784033,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709208',
            name: 'MARINA SANDES BARROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784033,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 9,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 7.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709208',
            name: 'MARINA SANDES BARROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784033,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709208',
            name: 'MARINA SANDES BARROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784033,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 3,
            partialGrade: 7,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 30168528,
        name: 'MATHEUS CORREIA PEREIRA',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 849767
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168528',
            name: 'MATHEUS CORREIA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849767,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 34,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168528',
            name: 'MATHEUS CORREIA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849767,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168528',
            name: 'MATHEUS CORREIA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849767,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 4,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 3.3333333333333335,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168528',
            name: 'MATHEUS CORREIA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849767,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168528',
            name: 'MATHEUS CORREIA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849767,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168528',
            name: 'MATHEUS CORREIA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849767,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168528',
            name: 'MATHEUS CORREIA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849767,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709209,
        name: 'MIRELLA VITÓRIA MARTINS BARBOSA',
        active: 0,
        occurrence_type_name: 'Transferido',
        has_academic_performance: 0,
        student_course_id: 784035
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709209',
            name: 'MIRELLA VITÓRIA MARTINS BARBOSA',
            active: 0,
            occurrence_type_name: 'Transferido',
            has_academic_performance: false,
            student_course_id: 784035,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 1,
            activitiesGrade: null,
            partialGrade: 1,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 1,
            approvedByGrade: false
          },
          frequency: {
            absence: 12,
            justifiedAbsence: 0,
            presence: 25,
            absenceInPercentage: 10,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709209',
            name: 'MIRELLA VITÓRIA MARTINS BARBOSA',
            active: 0,
            occurrence_type_name: 'Transferido',
            has_academic_performance: false,
            student_course_id: 784035,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 1,
            activitiesGrade: null,
            partialGrade: 1,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 1,
            approvedByGrade: false
          },
          frequency: {
            absence: 12,
            justifiedAbsence: 0,
            presence: 9,
            absenceInPercentage: 10,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709209',
            name: 'MIRELLA VITÓRIA MARTINS BARBOSA',
            active: 0,
            occurrence_type_name: 'Transferido',
            has_academic_performance: false,
            student_course_id: 784035,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 1,
            activitiesGrade: null,
            partialGrade: 1,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 1,
            approvedByGrade: false
          },
          frequency: {
            absence: 15,
            justifiedAbsence: 0,
            presence: 10,
            absenceInPercentage: 12.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709209',
            name: 'MIRELLA VITÓRIA MARTINS BARBOSA',
            active: 0,
            occurrence_type_name: 'Transferido',
            has_academic_performance: false,
            student_course_id: 784035,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 1,
            activitiesGrade: null,
            partialGrade: 1,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 1,
            approvedByGrade: false
          },
          frequency: {
            absence: 12,
            justifiedAbsence: 0,
            presence: 12,
            absenceInPercentage: 10,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709209',
            name: 'MIRELLA VITÓRIA MARTINS BARBOSA',
            active: 0,
            occurrence_type_name: 'Transferido',
            has_academic_performance: false,
            student_course_id: 784035,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 1,
            activitiesGrade: null,
            partialGrade: 1,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 1,
            approvedByGrade: false
          },
          frequency: {
            absence: 12,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 10,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709209',
            name: 'MIRELLA VITÓRIA MARTINS BARBOSA',
            active: 0,
            occurrence_type_name: 'Transferido',
            has_academic_performance: false,
            student_course_id: 784035,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 1,
            activitiesGrade: null,
            partialGrade: 1,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 1,
            approvedByGrade: false
          },
          frequency: {
            absence: 15,
            justifiedAbsence: 0,
            presence: 6,
            absenceInPercentage: 12.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709209',
            name: 'MIRELLA VITÓRIA MARTINS BARBOSA',
            active: 0,
            occurrence_type_name: 'Transferido',
            has_academic_performance: false,
            student_course_id: 784035,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 1,
            activitiesGrade: null,
            partialGrade: 1,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 1,
            approvedByGrade: false
          },
          frequency: {
            absence: 12,
            justifiedAbsence: 0,
            presence: 12,
            absenceInPercentage: 10,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709210,
        name: 'NICOLLY GONÇALVES DE CAMARGO',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784037
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709210',
            name: 'NICOLLY GONÇALVES DE CAMARGO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784037,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 34,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709210',
            name: 'NICOLLY GONÇALVES DE CAMARGO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784037,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709210',
            name: 'NICOLLY GONÇALVES DE CAMARGO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784037,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 4,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 3.3333333333333335,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709210',
            name: 'NICOLLY GONÇALVES DE CAMARGO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784037,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709210',
            name: 'NICOLLY GONÇALVES DE CAMARGO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784037,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709210',
            name: 'NICOLLY GONÇALVES DE CAMARGO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784037,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709210',
            name: 'NICOLLY GONÇALVES DE CAMARGO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784037,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 1.5,
            partialGrade: 5.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 5.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709211,
        name: 'NYCOLE SANTOS ADINOLFI',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 784038
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709211',
            name: 'NYCOLE SANTOS ADINOLFI',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784038,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709211',
            name: 'NYCOLE SANTOS ADINOLFI',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784038,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709211',
            name: 'NYCOLE SANTOS ADINOLFI',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784038,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709211',
            name: 'NYCOLE SANTOS ADINOLFI',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784038,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709211',
            name: 'NYCOLE SANTOS ADINOLFI',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784038,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709211',
            name: 'NYCOLE SANTOS ADINOLFI',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784038,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709211',
            name: 'NYCOLE SANTOS ADINOLFI',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784038,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709212,
        name: 'PEDRO RAFAEL MODESTO',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784039
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709212',
            name: 'PEDRO RAFAEL MODESTO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784039,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 37,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709212',
            name: 'PEDRO RAFAEL MODESTO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784039,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709212',
            name: 'PEDRO RAFAEL MODESTO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784039,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709212',
            name: 'PEDRO RAFAEL MODESTO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784039,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709212',
            name: 'PEDRO RAFAEL MODESTO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784039,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 6,
            activitiesGrade: null,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709212',
            name: 'PEDRO RAFAEL MODESTO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784039,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709212',
            name: 'PEDRO RAFAEL MODESTO',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784039,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709213,
        name: 'POLYANA ELOAH DE LIMA MEDEIROS',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784059
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709213',
            name: 'POLYANA ELOAH DE LIMA MEDEIROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784059,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 2,
            justifiedAbsence: 0,
            presence: 35,
            absenceInPercentage: 1.6666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709213',
            name: 'POLYANA ELOAH DE LIMA MEDEIROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784059,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709213',
            name: 'POLYANA ELOAH DE LIMA MEDEIROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784059,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 22,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709213',
            name: 'POLYANA ELOAH DE LIMA MEDEIROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784059,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709213',
            name: 'POLYANA ELOAH DE LIMA MEDEIROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784059,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 8,
            activitiesGrade: null,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709213',
            name: 'POLYANA ELOAH DE LIMA MEDEIROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784059,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709213',
            name: 'POLYANA ELOAH DE LIMA MEDEIROS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784059,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709214,
        name: 'RAÍSSA SANTOS DE ALMEIDA',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784062
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709214',
            name: 'RAÍSSA SANTOS DE ALMEIDA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784062,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 5,
            justifiedAbsence: 0,
            presence: 32,
            absenceInPercentage: 4.166666666666666,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709214',
            name: 'RAÍSSA SANTOS DE ALMEIDA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784062,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 12,
            justifiedAbsence: 0,
            presence: 9,
            absenceInPercentage: 10,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709214',
            name: 'RAÍSSA SANTOS DE ALMEIDA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784062,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 9,
            justifiedAbsence: 0,
            presence: 16,
            absenceInPercentage: 7.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709214',
            name: 'RAÍSSA SANTOS DE ALMEIDA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784062,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709214',
            name: 'RAÍSSA SANTOS DE ALMEIDA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784062,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709214',
            name: 'RAÍSSA SANTOS DE ALMEIDA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784062,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 12,
            justifiedAbsence: 0,
            presence: 9,
            absenceInPercentage: 10,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709214',
            name: 'RAÍSSA SANTOS DE ALMEIDA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784062,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709215,
        name: 'RIAN EZEQUIEL RIBEIRO REZENDE',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784063
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709215',
            name: 'RIAN EZEQUIEL RIBEIRO REZENDE',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784063,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 34,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709215',
            name: 'RIAN EZEQUIEL RIBEIRO REZENDE',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784063,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709215',
            name: 'RIAN EZEQUIEL RIBEIRO REZENDE',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784063,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 2,
            justifiedAbsence: 0,
            presence: 23,
            absenceInPercentage: 1.6666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709215',
            name: 'RIAN EZEQUIEL RIBEIRO REZENDE',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784063,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709215',
            name: 'RIAN EZEQUIEL RIBEIRO REZENDE',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784063,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 6,
            activitiesGrade: null,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709215',
            name: 'RIAN EZEQUIEL RIBEIRO REZENDE',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784063,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709215',
            name: 'RIAN EZEQUIEL RIBEIRO REZENDE',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784063,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709216,
        name: 'RUAN DOS PRAZERES HENCK',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784064
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709216',
            name: 'RUAN DOS PRAZERES HENCK',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784064,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 36,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709216',
            name: 'RUAN DOS PRAZERES HENCK',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784064,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709216',
            name: 'RUAN DOS PRAZERES HENCK',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784064,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709216',
            name: 'RUAN DOS PRAZERES HENCK',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784064,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709216',
            name: 'RUAN DOS PRAZERES HENCK',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784064,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 8,
            activitiesGrade: null,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709216',
            name: 'RUAN DOS PRAZERES HENCK',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784064,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709216',
            name: 'RUAN DOS PRAZERES HENCK',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784064,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709217,
        name: 'RYANN DE OLIVEIRA PEREIRA',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784065
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709217',
            name: 'RYANN DE OLIVEIRA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784065,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 2,
            justifiedAbsence: 0,
            presence: 35,
            absenceInPercentage: 1.6666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709217',
            name: 'RYANN DE OLIVEIRA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784065,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709217',
            name: 'RYANN DE OLIVEIRA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784065,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 22,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709217',
            name: 'RYANN DE OLIVEIRA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784065,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709217',
            name: 'RYANN DE OLIVEIRA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784065,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709217',
            name: 'RYANN DE OLIVEIRA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784065,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709217',
            name: 'RYANN DE OLIVEIRA PEREIRA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784065,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709218,
        name: 'SABRINA COELHO DOS SANTOS',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784067
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709218',
            name: 'SABRINA COELHO DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784067,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 4,
            justifiedAbsence: 0,
            presence: 33,
            absenceInPercentage: 3.3333333333333335,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709218',
            name: 'SABRINA COELHO DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784067,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709218',
            name: 'SABRINA COELHO DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784067,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 8,
            justifiedAbsence: 0,
            presence: 17,
            absenceInPercentage: 6.666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709218',
            name: 'SABRINA COELHO DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784067,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 9,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 7.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709218',
            name: 'SABRINA COELHO DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784067,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 8,
            activitiesGrade: null,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 9,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 7.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709218',
            name: 'SABRINA COELHO DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784067,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709218',
            name: 'SABRINA COELHO DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784067,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 30168538,
        name: 'SABRINA NEVES CORREA',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 849769
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168538',
            name: 'SABRINA NEVES CORREA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849769,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 74,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168538',
            name: 'SABRINA NEVES CORREA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849769,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 36,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168538',
            name: 'SABRINA NEVES CORREA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849769,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 2,
            justifiedAbsence: 0,
            presence: 48,
            absenceInPercentage: 1.6666666666666667,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168538',
            name: 'SABRINA NEVES CORREA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849769,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 48,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168538',
            name: 'SABRINA NEVES CORREA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849769,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 6,
            activitiesGrade: null,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 54,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168538',
            name: 'SABRINA NEVES CORREA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849769,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 39,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '30168538',
            name: 'SABRINA NEVES CORREA',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 849769,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 48,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709220,
        name: 'SARA CRISTINA CUGLER RODRIGUES',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784069
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709220',
            name: 'SARA CRISTINA CUGLER RODRIGUES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784069,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 36,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709220',
            name: 'SARA CRISTINA CUGLER RODRIGUES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784069,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709220',
            name: 'SARA CRISTINA CUGLER RODRIGUES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784069,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 1,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0.8333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709220',
            name: 'SARA CRISTINA CUGLER RODRIGUES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784069,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709220',
            name: 'SARA CRISTINA CUGLER RODRIGUES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784069,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 9,
            activitiesGrade: null,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709220',
            name: 'SARA CRISTINA CUGLER RODRIGUES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784069,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 18,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709220',
            name: 'SARA CRISTINA CUGLER RODRIGUES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784069,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709226,
        name: 'THIAGO THEOBALDINO MAGALHÃES',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784070
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709226',
            name: 'THIAGO THEOBALDINO MAGALHÃES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784070,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 37,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709226',
            name: 'THIAGO THEOBALDINO MAGALHÃES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784070,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 6,
            partialGrade: 9,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 9,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709226',
            name: 'THIAGO THEOBALDINO MAGALHÃES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784070,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 1.5,
            partialGrade: 4.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 4.5,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 25,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709226',
            name: 'THIAGO THEOBALDINO MAGALHÃES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784070,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 1.5,
            partialGrade: 4.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 4.5,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709226',
            name: 'THIAGO THEOBALDINO MAGALHÃES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784070,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 6,
            activitiesGrade: null,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709226',
            name: 'THIAGO THEOBALDINO MAGALHÃES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784070,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709226',
            name: 'THIAGO THEOBALDINO MAGALHÃES',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784070,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 1.5,
            partialGrade: 4.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 4.5,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709221,
        name: 'VICTOR HUGO ALMEIDA SANTOS',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 784072
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709221',
            name: 'VICTOR HUGO ALMEIDA SANTOS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784072,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709221',
            name: 'VICTOR HUGO ALMEIDA SANTOS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784072,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709221',
            name: 'VICTOR HUGO ALMEIDA SANTOS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784072,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709221',
            name: 'VICTOR HUGO ALMEIDA SANTOS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784072,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709221',
            name: 'VICTOR HUGO ALMEIDA SANTOS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784072,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709221',
            name: 'VICTOR HUGO ALMEIDA SANTOS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784072,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709221',
            name: 'VICTOR HUGO ALMEIDA SANTOS',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784072,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: null,
            activitiesGrade: null,
            partialGrade: null,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 0,
            approvedByGrade: false
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 0,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709223,
        name: 'VICTÓRIA KAROLINA NÓBREGA TUBIANO',
        active: 0,
        occurrence_type_name: 'Cancelado',
        has_academic_performance: 0,
        student_course_id: 784073
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709223',
            name: 'VICTÓRIA KAROLINA NÓBREGA TUBIANO',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784073,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 2,
            activitiesGrade: 6,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 16,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 13.333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709223',
            name: 'VICTÓRIA KAROLINA NÓBREGA TUBIANO',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784073,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 2,
            activitiesGrade: 6,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 15,
            justifiedAbsence: 0,
            presence: 6,
            absenceInPercentage: 12.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709223',
            name: 'VICTÓRIA KAROLINA NÓBREGA TUBIANO',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784073,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 2,
            activitiesGrade: 6,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 16,
            justifiedAbsence: 0,
            presence: 9,
            absenceInPercentage: 13.333333333333334,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709223',
            name: 'VICTÓRIA KAROLINA NÓBREGA TUBIANO',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784073,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 2,
            activitiesGrade: 6,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 18,
            justifiedAbsence: 0,
            presence: 6,
            absenceInPercentage: 15,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709223',
            name: 'VICTÓRIA KAROLINA NÓBREGA TUBIANO',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784073,
            academic_performance_grade: null,
            approved: false,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 1,
            activitiesGrade: null,
            partialGrade: 1,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 1,
            approvedByGrade: false
          },
          frequency: {
            absence: 15,
            justifiedAbsence: 0,
            presence: 12,
            absenceInPercentage: 12.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709223',
            name: 'VICTÓRIA KAROLINA NÓBREGA TUBIANO',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784073,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 2,
            activitiesGrade: 3,
            partialGrade: 5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 5,
            approvedByGrade: true
          },
          frequency: {
            absence: 18,
            justifiedAbsence: 0,
            presence: 3,
            absenceInPercentage: 15,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709223',
            name: 'VICTÓRIA KAROLINA NÓBREGA TUBIANO',
            active: 0,
            occurrence_type_name: 'Cancelado',
            has_academic_performance: false,
            student_course_id: 784073,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 2,
            activitiesGrade: 4.5,
            partialGrade: 6.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 18,
            justifiedAbsence: 0,
            presence: 6,
            absenceInPercentage: 15,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709224,
        name: 'VITÓRIA GABRIELY GONÇALVES DOS SANTOS',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784074
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709224',
            name: 'VITÓRIA GABRIELY GONÇALVES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784074,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 37,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709224',
            name: 'VITÓRIA GABRIELY GONÇALVES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784074,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709224',
            name: 'VITÓRIA GABRIELY GONÇALVES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784074,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 25,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709224',
            name: 'VITÓRIA GABRIELY GONÇALVES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784074,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 4.5,
            partialGrade: 7.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709224',
            name: 'VITÓRIA GABRIELY GONÇALVES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784074,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 8,
            activitiesGrade: null,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 27,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709224',
            name: 'VITÓRIA GABRIELY GONÇALVES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784074,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 21,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709224',
            name: 'VITÓRIA GABRIELY GONÇALVES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784074,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 3,
            activitiesGrade: 3,
            partialGrade: 6,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 6,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    },
    {
      student: {
        enrollment: 709225,
        name: 'VITÓRIA MORAES DOS SANTOS',
        active: 1,
        occurrence_type_name: null,
        has_academic_performance: 0,
        student_course_id: 784075
      },
      gradeDetails: [
        {
          discipline: {
            subjectId: 443212,
            name: 'Administração da Produção e Serviços',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709225',
            name: 'VITÓRIA MORAES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784075,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 4,
            justifiedAbsence: 0,
            presence: 33,
            absenceInPercentage: 3.3333333333333335,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443213,
            name: 'Administração de Recursos Humanos',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709225',
            name: 'VITÓRIA MORAES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784075,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443214,
            name: 'Administração Financeira e Orçamentária',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709225',
            name: 'VITÓRIA MORAES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784075,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 3,
            partialGrade: 7,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 19,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443215,
            name: 'Desenvolvimento de Modelos de Negócios',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709225',
            name: 'VITÓRIA MORAES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784075,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 4.5,
            partialGrade: 8.5,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8.5,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443216,
            name: 'Planejamento e Desenvolvimento do Trabalho de Conclusão de Curso (TCC) em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709225',
            name: 'VITÓRIA MORAES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784075,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 8,
            activitiesGrade: null,
            partialGrade: 8,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 8,
            approvedByGrade: true
          },
          frequency: {
            absence: 3,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 2.5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443217,
            name: 'Processos Logísticos Empresariais',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709225',
            name: 'VITÓRIA MORAES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784075,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 6,
            partialGrade: 10,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 10,
            approvedByGrade: true
          },
          frequency: {
            absence: 6,
            justifiedAbsence: 0,
            presence: 15,
            absenceInPercentage: 5,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        },
        {
          discipline: {
            subjectId: 443218,
            name: 'Tecnologia da Informação em Administração',
            workload: 90,
            beginAt: '2024-02-16T00:00:00.000Z',
            endAt: '2024-12-24T00:00:00.000Z'
          },
          student: {
            enrollment: '709225',
            name: 'VITÓRIA MORAES DOS SANTOS',
            active: 1,
            occurrence_type_name: null,
            has_academic_performance: false,
            student_course_id: 784075,
            academic_performance_grade: null,
            approved: true,
            situation: 'studying'
          },
          grade: {
            assessmentGrade: 4,
            activitiesGrade: 3,
            partialGrade: 7,
            recoveryGrade: null,
            reclassificationGrade: null,
            concept5Grade: null,
            finalGrade: 7,
            approvedByGrade: true
          },
          frequency: {
            absence: 0,
            justifiedAbsence: 0,
            presence: 24,
            absenceInPercentage: 0,
            maximumAbsence: 30,
            approvedByFrequency: true
          }
        }
      ]
    }
  ]
}
