import { ROUTE } from 'services/constants'

const breadcrumbsItems = [
  {
    path: ROUTE.CREATE_ACCESS_USER,
    label: 'Sistema'
  },
  {
    path: ROUTE.CREATE_ACCESS_USER,
    label: 'Acessos'
  },
  {
    path: ROUTE.CREATE_ACCESS_USER,
    label: '<b>Novo</b>'
  }
]

const initialState = {
  name: '',
  email: '',
  cpf: '',
  phone: '',
  login: '',
  user_nivel_id: '',
  user_sector: '',
  unit: ''
}

const MESSAGE = {
  NO_SEARCH: 'Não encontramos nenhum documento nos registros desta ficha.',
  SUCCESS: 'Acesso criado com sucesso!'
}

export { breadcrumbsItems, initialState, MESSAGE }
