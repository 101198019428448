import React, { useState } from 'react'
import {
  InputAdornment,
  TableCell,
  TableRow,
  Checkbox,
  TableBody
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { dateFormat, DATE_FORMAT } from 'services/helpers'
import { CHARACTER_LIMIT, minimumCharacters } from './constants'
import * as Styled from './style'

const TableFooter = ({
  handleClick,
  setContentCallBack,
  isItemSelected,
  labelId,
  row
}) => {
  const [values, setValues] = useState(row?.description)

  const onHandleDescription = (e) => {
    const { value } = e.target
    setValues(value)
  }

  const onHandlBlur = (id, doneAt) => {
    const descriptionLength = row?.description?.length
    const valuesLength = values?.trim()?.length

    if (descriptionLength !== valuesLength) {
      setContentCallBack(values?.trim(), id, doneAt)
    }
  }

  return (
    <TableBody key={row?.id}>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row?.id}
        selected={isItemSelected}
      >
        <TableCell
          padding="checkbox"
          onClick={(event) => handleClick(event, row?.id)}
        >
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': labelId
            }}
          />
        </TableCell>
        <TableCell component="th" id={labelId} scope="row" padding="none">
          {row?.index || '-'}
        </TableCell>
        <Styled.TableCell>
          {dateFormat(row?.done_at, DATE_FORMAT.DD_MM_YYYY) || '-'}
        </Styled.TableCell>
        <Styled.TableCell>{row?.professor_name || '-'}</Styled.TableCell>
        <Styled.TableCellDescription>
          <Styled.Input
            fullWidth
            id={`diary-content-${row?.id}`}
            label="Descrição da aula"
            type="text"
            variant="outlined"
            multiline
            value={values}
            onChange={(e) => onHandleDescription(e)}
            onBlur={() => onHandlBlur(row?.id, row?.done_at)}
            helperText={`${values?.length}/${CHARACTER_LIMIT} `}
            error={values?.length === minimumCharacters}
            InputProps={{
              inputProps: {
                maxLength: CHARACTER_LIMIT
              },
              startAdornment: (
                <InputAdornment position="start">
                  <EditIcon />
                </InputAdornment>
              )
            }}
          />
        </Styled.TableCellDescription>
        <Styled.TableCell>{row?.created_by || '-'}</Styled.TableCell>
      </TableRow>
    </TableBody>
  )
}

export default TableFooter
