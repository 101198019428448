import * as Yup from 'yup'
import { typesReports } from './constants'

export function getSchema() {
  return Yup.object().shape({
    typeReport: Yup.string()
      .nullable()
      .required('Selecionar a ata é obrigatório'),
    startDate: Yup.date()
      .nullable()
      .when('typeReport', {
        is: typesReports.partialReport,
        then: Yup.date().nullable().required('Campo obrigatório')
      }),
    endDate: Yup.date()
      .nullable()
      .when('typeReport', {
        is: typesReports.partialReport,
        then: Yup.date().nullable().required('Campo obrigatório')
      })
  })
}
