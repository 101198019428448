import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './styles'

const Counter = (props) => {
  const { title, number, fontColor } = props

  const formatedDecimalNumber =
    number < 10 ? '0' : `${String(number).substring(0, 1)}`

  const formatedUnitNumber =
    number < 10 ? `${String(number)}` : `${String(number).substring(1, 2)}`

  return (
    <Styled.Div>
      <Styled.DivNumber sx={{ color: fontColor }}>
        <Styled.Number>{formatedDecimalNumber}</Styled.Number>
        <Styled.Number>{formatedUnitNumber}</Styled.Number>
      </Styled.DivNumber>
      <Styled.Label>{title}</Styled.Label>
    </Styled.Div>
  )
}

Counter.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number
}

export default Counter
