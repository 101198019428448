import { ListUnifyUsersProvider } from 'components/pages/Admin/System/UnifyEnrollment/Context/listUsersContext'
import { InvoicesProvider } from 'components/pages/Admin/Financial/Invoices/Context/FormContext'
import { PoleProvider } from 'components/pages/Admin/Management/UnitsPole/ListPole/Context/FormContext'
import { UnitsProvider } from 'components/pages/Admin/Management/UnitsPole/ListUnits/Context/FormContext'
import { CuponProvider } from 'components/pages/Admin/Financial/Cupon/ListCupon/Context/FormContext'
import { TypedOfRequirementsProvider } from 'components/pages/Admin/Management/TypesOfRequirements/Context/FormContext'
import { PaymentPlansProvider } from 'components/pages/Admin/Financial/PaymentPlans/ListPaymentPlans/Context/FormContext'
import { FinancialBreakdownProvider } from 'components/pages/Admin/Financial/BillsReceive/FinancialBreakdown/Context/FormContext'
import { GroupsProvider } from 'components/pages/Admin/Financial/Groups/ListGroup/context/FormContext'
import { CoursesProvider } from 'components/pages/Admin/Management/Courses/ListCourse/Context/FormContext'
import { SubjectsProvider } from 'components/pages/Admin/Management/Subjects/ListSubjects/Context/FormnContext'
import { BillsToReceiveProvider } from 'components/pages/Admin/Financial/BillsReceive/ListBillsToReceive/Context/FormContext'
import { OccurrenceTypesProvider } from 'components/pages/Admin/Management/OccurrenceTypes/ListOccurrenceTypes/Context/FormContext'
import { CurriculumProvider } from 'components/pages/Admin/Management/Curriculum/ListCurriculum/Context/FormnContext'
import { SectorsProvider } from 'components/pages/Admin/System/Sectors/Context/FormContext'
import { ListSchoolClassProvider } from 'components/pages/Admin/Secretary/SchoolClass/ListSchoolClass/context/FormContext'
import { CreateStudentProvider } from 'components/pages/Admin/Secretary/Students/CreateStudent/Context/FormnContext'
import { HRProfessorProvider } from 'components/pages/Admin/HumanResources/Professor/ListProfessor/Context/FormnContext'
import { DeclarationsProvider } from 'components/pages/Admin/System/Declarations/context/FormContext'
import { AdditionalActivitiesProvider } from 'components/pages/Admin/Secretary/AdditionalActivitiesAvaliation/Context/FormContext'
import { GroupingProvider } from 'components/pages/Admin/Secretary/Grouping/Context/FormContext'
import { ContractsTypesProvider } from 'components/pages/Admin/System/ContractsTypes/context/FormContext'
import { TypedOfActivitysProvider } from 'components/pages/Admin/Management/TypesOfActivitys/Context/FormContext'
import { AcademicPerformancesProvider } from 'components/pages/Admin/Secretary/AcademicPerformances/ListAcademicPerformances/Context/FormContext'
import { DocumentsTypesProvider } from 'components/pages/Admin/System/DocumentTypes/Context/FormContext'
import { ReplaceClassProvider } from 'components/pages/Admin/HumanResources/Professor/CreateByUpdate/components/ReplaceClass/context/FormContext'
import { GroupingIncludeDisciplineProvider } from 'components/pages/Admin/Secretary/Grouping/IncludeDiscipline/Context/FormContext'
import { CampaignsProvider } from 'components/pages/Admin/Financial/Campaigns/context/FormContext'
import { LinkedClassProvider } from 'components/pages/Admin/HumanResources/Professor/CreateByUpdate/components/LinkedClass/context/FormContext'
import { TabActiveProvider } from 'components/pages/Admin/HumanResources/Professor/CreateByUpdate/components/ClassLinked/Context/TabContext'
import { TutorialProvider } from 'components/pages/Shared/Welcome/Context/TutorialContext'
import { TimeTrakingProvider } from 'components/pages/Admin/HumanResources/TimeTraking/List/Context/FormContext'
import { ManageClassesProvider } from 'components/pages/Admin/HumanResources/ManageClasses/List/Context/FormContext'
import { LoadingProvider } from 'components/pages/Shared/Loading/Context/LoadingContext'

const providers = [
  AcademicPerformancesProvider,
  HRProfessorProvider,
  ReplaceClassProvider,
  ListUnifyUsersProvider,
  ListUnifyUsersProvider,
  GroupingProvider,
  AcademicPerformancesProvider,
  DocumentsTypesProvider,
  GroupingIncludeDisciplineProvider,
  LinkedClassProvider,
  TabActiveProvider,
  ReplaceClassProvider,
  HRProfessorProvider,
  OccurrenceTypesProvider,
  CreateStudentProvider,
  TimeTrakingProvider,
  TutorialProvider,
  CurriculumProvider,
  SubjectsProvider,
  LinkedClassProvider,
  CampaignsProvider,
  FinancialBreakdownProvider,
  CampaignsProvider,
  BillsToReceiveProvider,
  CuponProvider,
  ListSchoolClassProvider,
  TypedOfRequirementsProvider,
  TypedOfActivitysProvider,
  PaymentPlansProvider,
  GroupsProvider,
  InvoicesProvider,
  CoursesProvider,
  UnitsProvider,
  SectorsProvider,
  AdditionalActivitiesProvider,
  DeclarationsProvider,
  ContractsTypesProvider,
  TabActiveProvider,
  DocumentsTypesProvider,
  PoleProvider,
  ManageClassesProvider,
  LoadingProvider
]

export const MainContext = ({ children }) =>
  providers.reduceRight(
    (composed, Provider) => <Provider>{composed}</Provider>,
    children
  )
