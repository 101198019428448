/* eslint-disable consistent-return */
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'

const breadcrumbsItems = [
  {
    path: ROUTE.contracts.LIST_CONTRACTS,
    label: '<b>Sistema - </b> Tipos de Contrato'
  }
]
const minCharacters = 3

const isDisabledButton = ({ name }) => {
  const enabled = name && name.length >= minCharacters
  return !enabled
}

const isDisabledButtonCreate = ({ name, description }) => {
  const enabled =
    name &&
    name.length >= minCharacters &&
    description &&
    description.length >= minCharacters
  return !enabled
}

const getTitleModalConfirm = (isActive) => (isActive ? 'Inativar' : 'Ativar')

const getMessageModalConfirm = (isActive, amount) => {
  const msgStartMessage = `Deseja ${isActive ? 'inativar' : 'ativar'}`
  return amount
    ? `${msgStartMessage} os ${amount} tipos de contratos selecionados?`
    : `${msgStartMessage} o contrato selecionado?`
}

const getClassModalConfirm = (isActive, amount) => {
  const inactivateText = TAG_MANAGER.sistema_btn_confirma_inativarContrato
  const activateText = TAG_MANAGER.sistema_btn_confirma_ativarContrato
  const inactivatePluralText =
    TAG_MANAGER.sistema_btn_confirma_inativarContratos
  const activatePluralText = TAG_MANAGER.sistema_btn_confirma_ativarContratos

  if (isActive) {
    return amount ? inactivatePluralText : inactivateText
  }

  return amount ? activatePluralText : activateText
}

export {
  breadcrumbsItems,
  isDisabledButton,
  getClassModalConfirm,
  isDisabledButtonCreate,
  getTitleModalConfirm,
  getMessageModalConfirm
}
