import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { START_YEAR_FOR_NOVOTEC } from 'services/constants/units'

const {
  borderLayoutDefault,
  paddingLayoutDefault,
  noBorderOnTop,
  getIsLikeNovotec
} = utilsPDF

const getRunningTextAboutClass = (info) => {
  const date = new Date()
  const year = date.getUTCFullYear()
  const mounth = date.toLocaleString('pt-BR', { month: 'long' })
  const day = date.getUTCDate()

  const isNovotec = getIsLikeNovotec(info.unit)
  const isFrom2024 = info.bimesterYear >= START_YEAR_FOR_NOVOTEC
  const isNovotecFrom2024 = isNovotec && isFrom2024

  return [
    {
      text: `Aos ${day} dias do mês de ${mounth} de ${year}, conclui-se o processo de apuração do Resultado dos alunos do(a) `
    },
    { text: `${info.course}, `, bold: true },
    {
      text: `turma ${info.classroom}, turno ${info.shift}, ${
        isNovotecFrom2024 ? `${info.bimester}º bimestre, ` : ''
      }desta instituição de Ensino com os seguintes resultados:`
    }
  ]
}

const buildClassInfo = (info) => {
  const isNovotec = getIsLikeNovotec(info.unit)
  const isFrom2024 = info.bimesterYear >= START_YEAR_FOR_NOVOTEC
  const isNovotecFrom2024 = isNovotec && isFrom2024

  return {
    table: {
      body: [
        [
          {
            stack: [{ text: 'Curso', bold: true }, { text: info.course }],
            border: noBorderOnTop
          },
          ...(isNovotecFrom2024
            ? [
                {
                  stack: [
                    { text: 'Bimestre', bold: true },
                    { text: `${info.bimester}º` }
                  ],
                  border: noBorderOnTop
                }
              ]
            : []),
          {
            stack: [
              { text: 'Ano letivo', bold: true },
              { text: info.bimesterYear }
            ],
            border: noBorderOnTop,
            colSpan: isNovotecFrom2024 ? 1 : 2
          },
          ...(!isNovotecFrom2024 ? [{}] : [])
        ],
        [{ text: getRunningTextAboutClass(info), colSpan: 3 }, {}, {}]
      ],
      widths: ['*', 50, 50]
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}

export default buildClassInfo
