import React, { Suspense } from 'react'
import { LoadingPage } from 'components/molecules'
import { ToastComponent } from 'components/atoms'
import * as Styled from './styles'

const TemplateRegistrationNewEnrollment = ({
  titleHeader,
  content,
  modal,
  snackAlert
}) => (
  <Suspense fallback={<LoadingPage open />}>
    <Styled.Content>
      <div>{titleHeader}</div>
      <div>{content}</div>
      <div>{modal}</div>
      <div>{snackAlert}</div>
    </Styled.Content>

    <ToastComponent />
  </Suspense>
)

export default TemplateRegistrationNewEnrollment
