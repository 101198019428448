import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useLocation, useNavigate } from 'react-router-dom'
import { STUDENT_ROUTE } from 'services/constants/routes/student'
import { useUserStore } from 'store/GlobalContext'
import ComputerIcon from '@mui/icons-material/Computer'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'

import PaidIcon from '@mui/icons-material/Paid'
import InfoIcon from '@mui/icons-material/Info'
import MessageIcon from '@mui/icons-material/Message'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import FacebookIcon from '@mui/icons-material/Facebook'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import WarningIcon from '@mui/icons-material/Warning'
import SpellcheckIcon from '@mui/icons-material/Spellcheck'

import Grid from '@mui/material/Unstable_Grid2'
import ButtonFlat from 'components/molecules/ButtonFlat'
import WarningCountDown from 'components/molecules/WarningCountDown'
import { formatDate, updateErrorMessage } from 'services/helpers'
import { useEffect, useState, useCallback } from 'react'
import { getStudentHasOverdue } from 'services/api/student'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { Button } from '@mui/material'
import { UNITS } from 'services/constants'
import * as Styled from './style'
import ModalStudentOverdue from '../Discipline/components/ModalStudentOverdue'
import {
  acceptTermReEnrollment,
  reEnrollmentList
} from '../ReEnrollment/components/Content/service'

const StudentHub = () => {
  const navigate = useNavigate()
  const userStore = useUserStore()
  const location = useLocation()
  const names = userStore.name.trim().split(' ')
  const initials = names.map((n) => n.substring(0, 1).toUpperCase())
  const firstName = names[0]
  const { setLoading } = useLoading()

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const [openModalStudentOverdue, setOpenModalStudentOverdue] = useState(false)
  const [studentOverdue, setStudentOverdue] = useState({})
  const today = new Date()
  const { username: id, unit } = userStore

  const displayFinance = !(
    unit === UNITS.NOVOTEC.name || unit === UNITS.MS.name
  )

  const callbackSuccess = () => {}

  const handleAcceptTerm = async ({
    isAutomatic = false,
    studentReenrollmentId = null
  }) => {
    await acceptTermReEnrollment({
      id: studentReenrollmentId ?? userStore.student_reenrollment_id,
      callbackSuccess,
      isAutomatic
    })
  }

  const configWarning = (term) => {
    if (!term?.reenrollment_deadline_date) return null

    let bills = ''
    let message = ''
    let header = ''
    let colorWarning = ''
    let footer = ''
    let displayClock = true
    let deadline = term?.reenrollment_deadline_date
    let displaySeconds
    let onClick

    const deadlineStart = new Date(term?.reenrollment_deadline_start_date)

    const buttonStyle = {
      backgroundColor: '#FAFAFA',
      borderRadius: '20px',
      textDecoration: 'underline',
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '0.85rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.2rem'
      }
    }

    if (
      deadlineStart.getTime() > today.getTime() &&
      term.pending_financial_situation
    ) {
      header = 'Período de rematrícula'
      colorWarning = theme.palette.hub.blue
      displayClock = true
      deadline = term.reenrollment_deadline_start_date
      bills = `${term.total_bills} título(s) pendente(s). Veja aqui.`
      displaySeconds = false
      onClick = () => {
        navigate(STUDENT_ROUTE.STUDENT_FINANCIAL)
      }
      message =
        'O período de rematrícula se inicia em breve e você possui pendência(s):'
      footer = (
        <Button
          onClick={onClick}
          sx={{
            color: colorWarning,
            ...buttonStyle,
            '&.MuiButton-root:hover': { bgcolor: 'white' }
          }}
        >
          <WarningIcon
            sx={{
              verticalAlign: 'text-bottom',
              [theme.breakpoints.down('md')]: {
                fontSize: '1.1rem'
              }
            }}
          />{' '}
          {bills}
        </Button>
      )
    }

    if (
      deadlineStart.getTime() <= today.getTime() &&
      !term?.reenrollment_deadline_expired &&
      term?.pending_financial_situation
    ) {
      bills = `${term.total_bills} Título(s) pendente(s). Veja aqui.`
      header = 'Fim do período de rematrícula'
      colorWarning = theme.palette.hub.orange
      displayClock = true
      displaySeconds = true
      deadline = term.reenrollment_deadline_date
      onClick = () => {
        navigate(STUDENT_ROUTE.STUDENT_FINANCIAL)
      }
      message =
        'O período de rematrícula acaba em breve e você possui pendência(s):'
      footer = (
        <Button
          onClick={onClick}
          sx={{
            color: colorWarning,
            ...buttonStyle,
            '&.MuiButton-root:hover': { bgcolor: 'white' }
          }}
        >
          <WarningIcon
            sx={{
              verticalAlign: 'text-bottom',
              [theme.breakpoints.down('md')]: {
                fontSize: '1.1rem'
              }
            }}
          />{' '}
          {bills}
        </Button>
      )
    }

    if (term?.reenrollment_deadline_expired) {
      bills = `${term.total_bills} título(s) pendente(s).`
      message = `A data limite da rematrícula foi ultrapassada (${formatDate(
        term?.reenrollment_deadline_date
      )}).`
      header = 'Período de rematrícula encerrado!'
      colorWarning = theme.palette.hub.red
      displayClock = false
      footer = (
        <div style={{ textAlign: 'center' }}>
          Entre em contato com a Secretaria/Coordenação para regularizar suas
          pendências e confirmar sua rematrícula. <br />{' '}
          <WarningIcon
            sx={{
              verticalAlign: 'text-bottom',
              [theme.breakpoints.down('md')]: {
                fontSize: '1.1rem'
              }
            }}
          />{' '}
          {bills}
        </div>
      )
    }

    return (
      <WarningCountDown
        backgroundColor={colorWarning}
        header={header}
        deadline={deadline}
        displayClock={displayClock}
        message={message}
        footer={footer}
        displaySeconds={displaySeconds}
        height="150px"
      />
    )
  }

  const handleStudentHasOverdue = async ({ register }) => {
    const {
      data: overdue,
      error,
      status
    } = await getStudentHasOverdue({ register })

    if (error) {
      updateErrorMessage({
        error,
        status
      })
    }

    if (location.state?.term) return

    if (overdue) {
      setStudentOverdue(overdue)
      if (overdue.has_overdue) setOpenModalStudentOverdue(true)
    }
  }

  useEffect(async () => {
    setLoading(true)
    await Promise.all([handleStudentHasOverdue({ register: id })])
    setLoading(false)
  }, [id])

  useEffect(async () => {
    setLoading(true)
    const reEnrollmentInfo = await reEnrollmentList({ username: id })

    if (
      reEnrollmentInfo &&
      !reEnrollmentInfo?.reenrollment_deadline_expired &&
      !reEnrollmentInfo?.pending_financial_situation
    ) {
      await handleAcceptTerm({
        isAutomatic: true,
        studentReenrollmentId: reEnrollmentInfo.student_reenrollment_id
      })
    }
    setLoading(false)
  }, [])

  return (
    <>
      <Styled.Container>
        <Styled.Header />
        <Styled.Body>
          <Styled.HeaderCircle>
            {initials[0]}
            {initials[1]}
          </Styled.HeaderCircle>
          <Styled.Greeting>Olá, {firstName}</Styled.Greeting>
          <Grid container spacing={2}>
            {matches && (
              <Grid xs={12}>
                <ButtonFlat
                  orientation="horizontal"
                  backgroundColor={theme.palette.hub.purple}
                  label="Atendimento"
                  icon={MessageIcon}
                  fontColor="white"
                />
              </Grid>
            )}
            <Grid xs={12}>{configWarning(location.state?.term)}</Grid>
            <Grid xs={4} md={6}>
              <ButtonFlat
                orientation="vertical"
                backgroundColor={theme.palette.hub.lightPurple}
                label="EAD"
                icon={ComputerIcon}
                fontColor="white"
                onClick={() => {
                  navigate('../JoyClass')
                }}
                height="68px"
              />
            </Grid>
            <Grid xs={4} md={6}>
              <ButtonFlat
                orientation="vertical"
                backgroundColor={theme.palette.hub.darkBlue}
                label="Notas"
                icon={SpellcheckIcon}
                fontColor="white"
                onClick={() => {
                  navigate(STUDENT_ROUTE.STUDENT_MY_SCORES)
                }}
                height="68px"
              />
            </Grid>
            {displayFinance && (
              <Grid xs={4} md={6}>
                <ButtonFlat
                  orientation="vertical"
                  backgroundColor={theme.palette.hub.green}
                  label="Financeiro"
                  icon={PaidIcon}
                  fontColor="white"
                  onClick={() => {
                    navigate(STUDENT_ROUTE.STUDENT_FINANCIAL)
                  }}
                  height="68px"
                />
              </Grid>
            )}
            <Grid xs={4} md={6}>
              <ButtonFlat
                orientation="vertical"
                backgroundColor={theme.palette.hub.orange}
                label="Unidade"
                icon={InfoIcon}
                fontColor="white"
                height="68px"
              />
            </Grid>
            {/* <Grid xs={4} md={6}>   // -- >    BOTÃO DE  JORNADA SERA LIBERADO  NA V2  DO HUB
      <ButtonFlat
        orientation="vertical"
        backgroundColor="#2196F3"
        label="Jornada"
        icon={AccountTreeIcon}
        fontColor="white"
        height="68px"
      />
    </Grid> */}
            <Grid container xs={12}>
              <Grid xs={12}>
                <Styled.SocialMediaTitle>
                  Conecte-se com a Proz
                </Styled.SocialMediaTitle>
              </Grid>
              <Grid xs={6}>
                <Styled.SocialMediaContainer>
                  <Styled.SocialMediaIcon
                    onClick={() => {
                      navigate('https://www.instagram.com/prozeducacaobr/')
                    }}
                  >
                    <InstagramIcon />
                  </Styled.SocialMediaIcon>
                  <Styled.SocialMediaIcon>
                    <YouTubeIcon />
                  </Styled.SocialMediaIcon>
                  <Styled.SocialMediaIcon>
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="tiktok-svgrepo-com 1">
                        <path
                          id="primary"
                          d="M21.875 7.2917V9.37503C21.875 9.6513 21.7653 9.91625 21.5699 10.1116C21.3746 10.3069 21.1096 10.4167 20.8333 10.4167C19.3698 10.4141 17.9327 10.0261 16.6667 9.2917V16.1459C16.667 17.3486 16.347 18.5296 15.7395 19.5676C15.132 20.6056 14.259 21.4631 13.2103 22.0518C12.1616 22.6406 10.9749 22.9394 9.77243 22.9174C8.56993 22.8955 7.39498 22.5537 6.36839 21.9271C5.34181 21.3005 4.50064 20.4118 3.93141 19.3524C3.36217 18.2929 3.0854 17.101 3.12957 15.8991C3.17373 14.6972 3.53724 13.5288 4.1827 12.514C4.82816 11.4991 5.73228 10.6746 6.80208 10.125C6.96216 10.0398 7.14173 9.99776 7.32302 10.0032C7.50432 10.0086 7.68105 10.0613 7.83574 10.156C7.99042 10.2507 8.1177 10.3842 8.20497 10.5432C8.29225 10.7022 8.33649 10.8812 8.33333 11.0625V13.6875C8.3222 13.9322 8.21784 14.1633 8.04167 14.3334C7.66757 14.7118 7.41891 15.1961 7.32941 15.7206C7.23991 16.2451 7.3139 16.7845 7.54135 17.2656C7.76879 17.7466 8.13869 18.1461 8.60087 18.4098C9.06305 18.6734 9.59515 18.7886 10.125 18.7396C10.7904 18.6549 11.401 18.3273 11.8394 17.8196C12.2778 17.312 12.5131 16.6602 12.5 15.9896V3.12503C12.5 2.84876 12.6097 2.58381 12.8051 2.38846C13.0004 2.19311 13.2654 2.08336 13.5417 2.08336H15.7396C15.9877 2.07975 16.229 2.16487 16.42 2.32338C16.611 2.48188 16.7391 2.70337 16.7813 2.94795C16.9785 3.87808 17.488 4.71277 18.2251 5.31343C18.9622 5.91408 19.8825 6.24457 20.8333 6.25003C21.1096 6.25003 21.3746 6.35978 21.5699 6.55513C21.7653 6.75048 21.875 7.01543 21.875 7.2917Z"
                          fill="#9E9E9E"
                        />
                      </g>
                    </svg>
                  </Styled.SocialMediaIcon>
                  <Styled.SocialMediaIcon>
                    <FacebookIcon />
                  </Styled.SocialMediaIcon>
                </Styled.SocialMediaContainer>
              </Grid>
            </Grid>
          </Grid>
        </Styled.Body>
      </Styled.Container>
      <ModalStudentOverdue
        open={openModalStudentOverdue}
        amountOverdueBills={studentOverdue?.total_bills}
        onClose={() => setOpenModalStudentOverdue(false)}
      />
    </>
  )
}

export default StudentHub
