import { lazy, useEffect, useState } from 'react'
import { ListAlt } from '@mui/icons-material'
import { useSnack, usePaginationBasic } from 'services/hooks'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { IsEmpty } from 'services/helpers'
import { Form as FormDocumentType, List, SearchForm } from './components'
import {
  listDocumentTypes,
  updateDocumentTypes,
  createDocumentTypes
} from './services'
import {
  MESSAGE,
  INITIAL_STATE,
  DEFAULT_ITEMS_PER_PAGE,
  EDIT,
  NEW,
  nameAccordion,
  emptyStateMessage
} from './constants'
import { useDocumentsTypes } from './Context/FormContext'

const ModalContent = lazy(() => import('components/molecules/ModalContent'))

const DocumentTypes = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [modalContent, setModalContent] = useState({ isOpen: false, type: NEW })
  const [documentTypes, setDocumentTypes] = useState([])
  const [count, setCount] = useState(null)
  const [filter, setFilter] = useState(DEFAULT_ITEMS_PER_PAGE)
  const [documentType, setDocumentType] = useState(INITIAL_STATE)
  const {
    page: currentPage,
    totalPage,
    items,
    setPage
  } = usePaginationBasic(documentTypes, filter, count)
  const { formValues, setFormValues, resetFormValues } = useDocumentsTypes()

  const handleSetModalContent = (formType) => {
    setDocumentType(INITIAL_STATE)
    setModalContent((prevState) => ({
      isOpen: !prevState.isOpen,
      type: formType || NEW
    }))
  }

  const onSearch = async ({ name, inactive }) => {
    await listDocumentTypes({
      inactive,
      name,
      filter,
      currentPage,
      setSnack,
      setDocumentTypes,
      setLoadingOpen,
      setCount
    })
  }

  const onSubmit = (values) => {
    const { id, ...payload } = values
    if (modalContent?.type === NEW) createDocumentTypes({ setSnack, payload })
    if (modalContent?.type === EDIT)
      updateDocumentTypes({
        id,
        payload,
        setSnack,
        setLoadingOpen
      })

    setModalContent({ isOpen: false })

    return setTimeout(() => onSearch(formValues), 3000)
  }

  const handleUpdateDocumentStatus = async ({ id, isActive, setModal }) => {
    await updateDocumentTypes({
      id,
      payload: { active: isActive },
      message: isActive ? MESSAGE.SUCCESS_ACTIVE : MESSAGE.SUCCESS_INACTIVE,
      setSnack,
      setLoadingOpen
    })

    setModal(false)

    return setTimeout(() => onSearch(formValues), 3000)
  }

  const handleResetSearch = (resetForm) => {
    resetForm()
    resetFormValues()
    setDocumentTypes([])
  }

  const onSubmitSearch = async (payload) => {
    setFormValues(payload)
    onSearch(payload)
  }

  useEffect(() => onSearch(formValues), [filter, currentPage])

  return (
    <TemplateDefaultFormAndResult
      noFilter
      titleHeader="Sistema - Tipos de Documentos"
      snackProps={snackProps}
      labelButtonHeader="Novo Tipo"
      onClickButton={() => handleSetModalContent(NEW)}
      loadingOpen={loadingOpen}
      listItems={documentTypes}
      accordion={
        <SearchForm
          onSubmit={onSubmitSearch}
          handleResetSearch={handleResetSearch}
        />
      }
      nameAccordion={nameAccordion}
      expanded={expanded}
      setExpanded={setExpanded}
      iconInformative={<ListAlt />}
      messageInformative={emptyStateMessage}
    >
      {!IsEmpty(documentTypes) && (
        <List
          handleSetModalContent={handleSetModalContent}
          handleUpdateDocumentStatus={handleUpdateDocumentStatus}
          count={count}
          filter={filter}
          setFilter={setFilter}
          setPage={setPage}
          totalPage={totalPage}
          currentPage={currentPage}
          rowsItems={items}
          setDocumentType={setDocumentType}
        />
      )}

      <ModalContent
        content={
          <FormDocumentType
            type={modalContent.type}
            handleSetModalContent={handleSetModalContent}
            onSubmit={onSubmit}
            initialValues={documentType}
          />
        }
        maxWidth="md"
        open={modalContent.isOpen}
        title={`${
          modalContent?.type === EDIT ? 'Editar' : 'Novo'
        } Tipo de Documento`}
      />
    </TemplateDefaultFormAndResult>
  )
}

export default DocumentTypes
