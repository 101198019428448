import theme from 'theme/designTokens'
import generateDocDefinitionsBase from '../shared/BasePage'
import {
  DOCUMENT_TITLE,
  MAX_SUBJECTS_PER_PAGE,
  DOCUMENT_TITLE_PARTIAL_REPORT
} from './constants'
import { utilsPDF } from '../shared/utils'
import buildSignature from './Signature'
import { buildClassInfo } from './ClassInfo'
import buildTable from './Table'

const { chunkArray } = utilsPDF

function getFinalResult({ student, gradeDetails }) {
  if (!student.active) {
    return student.occurrence_type_name
  }

  const approved = gradeDetails.every(
    (gradeDetail) => gradeDetail.student.approved
  )

  return approved ? 'Aprovado' : 'Reprovado'
}

const buildResultReportContent = ({
  classInfo,
  students,
  subjects,
  isPartialReport
}) => {
  const classWorkload = subjects.reduce(
    (acc, current) => acc + current.workload,
    0
  )

  const subjectsChunk = chunkArray(subjects, MAX_SUBJECTS_PER_PAGE)
  const studentWithGradesChunked = students.map(
    ({ student, gradeDetails }) => ({
      student: {
        ...student,
        result: getFinalResult({ student, gradeDetails })
      },
      gradeDetails: chunkArray(gradeDetails, MAX_SUBJECTS_PER_PAGE)
    })
  )

  return subjectsChunk.map((chunk, indexChunk) => [
    buildClassInfo(classInfo),
    ...buildTable({
      subjects: chunk,
      students: studentWithGradesChunked,
      classInfo,
      indexChunk,
      classWorkload,
      isPartialReport
    }),
    buildSignature({
      signatures: classInfo.signatures,
      directorName: classInfo.directorUnitName,
      secretaryUnitName: classInfo.secretaryUnitName,
      schoolName: classInfo.unit,
      city: classInfo.unitCity
    })
  ])
}

export async function ResultReportPDF({ finalReportData, isPartialReport }) {
  const signatureImage1 = finalReportData?.classInfo?.signatures[0]?.image
  const signatureImage2 = finalReportData?.classInfo?.signatures[1]?.image

  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage1 || signatureImage2
        ? {
            images: {
              ...(signatureImage1
                ? {
                    signature1: {
                      url: finalReportData.classInfo.signatures[0].image,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {}),
              ...(signatureImage2
                ? {
                    signature2: {
                      url: finalReportData.classInfo.signatures[1].image,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {})
            }
          }
        : {}),
      pageBreakBefore: (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) => {
        const shouldBreakBefore =
          currentNode?.id === 'signatureInfo' &&
          currentNode.pageNumbers.length > 1

        return shouldBreakBefore
      }
    }
  })

  return docDefinitionsBase({
    documentTitle: isPartialReport
      ? DOCUMENT_TITLE_PARTIAL_REPORT
      : DOCUMENT_TITLE,
    mainContent: buildResultReportContent({
      classInfo: finalReportData.classInfo,
      students: finalReportData.students,
      subjects: finalReportData.subjects,
      isPartialReport
    }),
    companyInfo: finalReportData.companyInfo
  })
}
