export const numberSelected = 0
export const indexDefault = -1
export const isSelected = 1
export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultName = 'name'
export const typeAll = 'Tudo'
export const defaultTotalPage = 1

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nome'
  },
  {
    id: 'access_profile',
    numeric: false,
    disablePadding: false,
    label: 'Perfil de acesso'
  },
  {
    id: 'login',
    numeric: false,
    disablePadding: false,
    label: 'Login'
  },
  {
    id: 'sector',
    numeric: false,
    disablePadding: false,
    label: 'Setor'
  },
  {
    id: 'company_location',
    numeric: false,
    disablePadding: false,
    label: 'Alocação'
  },
  {
    id: 'unit',
    numeric: false,
    disablePadding: false,
    label: 'Unidade'
  }
]

export { headCells }
