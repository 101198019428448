import { ListAlt, Search } from '@mui/icons-material'

const initialState = {
  name: '',
  profile: '',
  sector: '',
  unit: '',
  inactive: false
}

const MESSAGE = {
  NO_SEARCH: {
    icon: <ListAlt />,
    text: '<strong>Encontre as pessoas com Acesso ao sistema</strong><br />Selecione um critério e realize uma busca para encontrar um acesso, ou busque por todos'
  },
  NOT_FOUND_SEARCH: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br />Verifique os dados informados e tente novamente.'
  }
}

const DEFAULT_ITEMS_PER_PAGE = 10
const DEFAULT_PAGE = 1
const defaultTotalPage = 10

export {
  initialState,
  MESSAGE,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  defaultTotalPage
}
