export const typeMask = (type, value) => {
  if (!value) return ''

  if (type === 'NUMBER') {
    return value?.replace(/\D+/g, '')
  }

  if (type === 'ENROLLMENT') {
    return value?.replace(/\D+/g, '')
  }

  if (type === 'CPF') {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }

  if (type === 'PIS') {
    return value.replace(/^(\d{3})(\d{5})(\d{2})(\d{1}).*/, '$1.$2.$3-$4')
  }

  if (type === 'RG') {
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{1}).*/, '$1.$2.$3-$4')
  }

  if (type === 'CEP') {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1')
  }

  if (type === 'CNPJ') {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  }

  if (type === 'PHONE') {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+?$/, '$1')
  }

  if (type === 'TELEPHONE') {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  }

  if (type === 'RG') {
    return value
      .replace(/\D+/g, '')
      .replace(/(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4')
  }

  if (type === 'MONEY') {
    const cleanValue = +value?.replace(/\D+/g, '')
    const options = { style: 'currency', currency: 'BRL' }
    return new Intl.NumberFormat('pt-br', options).format(cleanValue / 100)
  }

  if (type === 'MONEY_NUMBER') {
    const options = { style: 'currency', currency: 'BRL' }
    return new Intl.NumberFormat('pt-br', options).format(value)
  }

  if (type === 'PERCENT') {
    const options = { style: 'percent', currency: 'BRL' }
    return new Intl.NumberFormat('pt-br', options).format(value)
  }
  if (type === 'PERCENT_FORMAT') {
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    return new Intl.NumberFormat('pt-BR', options).format(value)
  }

  return value
}

export const parserMinutesForHours = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const minutesRemaining = minutes % 60
  return `${hours.toString().padStart(2, '0')}:${minutesRemaining
    .toString()
    .padStart(2, '0')}`
}

const unmaskMoneyValue = (moneyString) => {
  if (moneyString === '') return 0

  const numericString = moneyString.replace(/[^\d,]/g, '').replace(',', '.')
  return parseFloat(numericString)
}

export const currencyMask = (value) => {
  if (!value) return ''
  const result = value?.replace(/\D/g, '')

  if (result.length === 1) {
    return result?.replace(/^(\d)/, 'R$ 0,0$1')
  }

  if (result.length === 2) {
    return result?.replace(/^(\d\d)/, 'R$ 0,$1')
  }

  if (result.length === 3) {
    return result?.replace(/^(^0?)(^\d)(\d\d$)/, 'R$ $2,$3')
  }

  if (result.length >= 4 && result.length <= 5) {
    return result?.replace(/^(^0?)(\d*)(\d\d$)/, 'R$ $2,$3')
  }

  if (result.length >= 6 && result.length <= 8) {
    return result?.replace(/^(\d*)(\d{3})(\d\d$)/, 'R$ $1.$2,$3')
  }

  if (result.length >= 9) {
    return result?.replace(/^(\d)(\d{3})(\d{3})(\d{2}).*/, 'R$ $1.$2.$3,$4')
  }

  return result
}

export const currencyFormat = (value) => {
  const valueParsed = value && parseFloat(value?.toString())

  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(valueParsed)
}

export const currencyMaskWithoutPrefix = (value) => {
  if (!value) return ''

  const result = currencyMask(value)

  return result?.replace(/^R\$ /, '')
}

export const removeCurrencyMask = (value) => {
  if (!value) return ''
  if (Number.isInteger(value) === true) return value

  return parseFloat(value?.replace('R$', '').replace('.', '').replace(',', '.'))
}

const maskForNumber = (value) => {
  if (!value) return ''

  return value.match(/\d+/)[0]
}
const getMoneyFormatToNumber = (value) => {
  if (!value) return ''

  const formattedValue = value
    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    .trim()
    .replace('R$', '')
    .replace(/\./g, '')
    .replace(',', '.')

  return Number(formattedValue)
}

const getMoneyFormattedToBRL = (value) => {
  if (!value) return ''

  return value
    .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    .replace('R$', '')
    .trim()
}

const obscureEmail = (email) => {
  const [name, domain] = email.split('@')
  const MASK_LENGTH = 3
  const domainArr = domain.split('.')
  const domainNameWithAsterisk = new Array(MASK_LENGTH).join('*')
  const domainNameStr = `${domainNameWithAsterisk}${domainArr.shift().slice(2)}`
  return `${name.slice(0, MASK_LENGTH)}${new Array(
    name.length - MASK_LENGTH
  ).join('*')}@${domainNameStr}.${domainArr.join('.')}`
}

const obscurePhone = (phone) => {
  const phoneParts = phone.split('-')
  const MIN_LENGTH_PART_PHONE = 4
  const NO_MASK_LENGTH = 3
  const getAsteriskInLastChars = (str) =>
    str.length > MIN_LENGTH_PART_PHONE &&
    `${str.slice(0, -MIN_LENGTH_PART_PHONE)}${new Array(
      str.length - NO_MASK_LENGTH
    ).join('*')}`

  const phoneHasMoreThanOnePart = phoneParts.length > 1
  const [firstPhonePart] = phoneParts
  if (phoneHasMoreThanOnePart) {
    const firstStr = getAsteriskInLastChars(firstPhonePart)
    phoneParts.shift()
    return `${firstStr}${phoneParts.join(' ')}`
  }
  const MIN_FULL_LENGHT_PHONE = phone.length > 8
  if (!MIN_FULL_LENGHT_PHONE) return ''

  const NO_LENGTH_MASK_REST = 6
  const firstTwoChars = firstPhonePart.slice(0, 2)
  const lastFourChars = firstPhonePart.slice(-MIN_LENGTH_PART_PHONE)
  return `${firstTwoChars}${new Array(
    firstPhonePart.length - NO_LENGTH_MASK_REST
  ).join('*')}${lastFourChars}`
}

function brazilianCurrencyFormat(input, removeR$ = false) {
  let value = input

  if (typeof value === 'number' && !Number.isInteger(value)) {
    // Sendo número,
    value = parseFloat(value).toFixed(2)
  }

  // Remove todos os caracteres não numéricos
  value = String(value).replace(/\D/g, '')

  // Se o valor for vazio ou zero, retorna "R$0,00"
  if (value === '') {
    return `${removeR$ ? '' : 'R$'} 0,00`
  }

  // Converte o valor para um número de ponto flutuante e divide por 100
  const numberValue = parseFloat(value) / 100

  // Formata o número como uma string com duas casas decimais
  const formattedValue = numberValue.toFixed(2).replace('.', ',')

  // Adiciona a pontuação dos milhares
  const parts = formattedValue.split(',')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  // Adiciona o prefixo "R$" e retorna o valor formatado
  return `${removeR$ ? '' : 'R$ '}${parts.join(',')}`
}

export {
  maskForNumber,
  getMoneyFormatToNumber,
  getMoneyFormattedToBRL,
  obscureEmail,
  obscurePhone,
  unmaskMoneyValue,
  brazilianCurrencyFormat
}
