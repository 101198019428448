import React, { useState } from 'react'
import { TableRow } from '@mui/material'
import * as Styled from './style'

const TableFooter = ({ row }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <TableRow hover key={row?.user_id}>
      <Styled.TableCellTitle>{row?.name}</Styled.TableCellTitle>

      <Styled.TableCell>{row?.access_profile}</Styled.TableCell>
      <Styled.TableCell>{row?.login}</Styled.TableCell>
      <Styled.TableCell>{row?.sector?.name}</Styled.TableCell>
      <Styled.TableCell>{row?.company_location}</Styled.TableCell>
      <Styled.TableCell>{row?.unit}</Styled.TableCell>
    </TableRow>
  )
}

export default TableFooter
