import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  Tooltip,
  IconButton
} from '@mui/material'
import { RemoveCircleOutline, RotateLeft } from '@mui/icons-material'
import { booleanToString, paginationFilter } from 'services/helpers'
import { useSortTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced, ModalConfirm } from 'components/molecules'
import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  EDIT,
  HEAD_CELLS,
  OPTIONS_TYPE
} from '../../constants'
import * as Styled from './style'

export default function List({
  handleSetModalContent,
  handleUpdateDocumentStatus,
  count,
  filter,
  setFilter,
  setPage,
  totalPage,
  currentPage,
  rowsItems,
  setDocumentType
}) {
  const iconProps = { fontSize: 'small', color: 'secondary' }
  const tooltipProps = {
    [true]: {
      title: 'Inativar Documento',
      icon: <RemoveCircleOutline {...iconProps} />
    },
    [false]: {
      title: 'Ativar Documento',
      icon: <RotateLeft {...iconProps} />
    }
  }
  const [selectedId, setSelectedId] = useState(null)
  const [openModalActiveDocumentType, setOpenModalActiveDocumentType] =
    useState(false)
  const [openModalInactiveDocumentType, setOpenModalInactiveDocumentType] =
    useState(false)

  const PlusOne = () => <Styled.PlusOne>+1</Styled.PlusOne>

  const { handleRequestSort, order, orderBy, sortTable } =
    useSortTable(rowsItems)

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  const onClickFn = (isActive) =>
    isActive
      ? setOpenModalInactiveDocumentType(true)
      : setOpenModalActiveDocumentType(true)

  return (
    <Styled.Wrapper>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={HEAD_CELLS}
          />
          <TableBody>
            {sortTable?.map(
              ({
                id,
                name,
                type,
                extension,
                is_multiple: isMultiple,
                active: isActive,
                is_required: isRequired
              }) => (
                <Styled.TableRow key={id}>
                  <TableCell padding="checkbox" style={{ width: '55%' }}>
                    <Styled.WrapperLink>
                      {isActive ? (
                        <Tooltip title="Editar Documento" placement="top" arrow>
                          <Link
                            component="button"
                            href="#root"
                            onClick={() => {
                              handleSetModalContent(EDIT)
                              setDocumentType({
                                id,
                                name,
                                type,
                                extension,
                                is_required: isRequired,
                                is_multiple: isMultiple
                              })
                            }}
                          >
                            {name}
                          </Link>
                        </Tooltip>
                      ) : (
                        <Styled.Nolink>{name}</Styled.Nolink>
                      )}

                      {isMultiple && <PlusOne />}
                      <Styled.WrapperActionButtons>
                        <Tooltip
                          title={tooltipProps[isActive].title}
                          placement="top"
                        >
                          <IconButton
                            size="small"
                            disableRipple
                            onClick={() => {
                              setSelectedId(id)
                              onClickFn(isActive)
                            }}
                          >
                            {tooltipProps[isActive].icon}
                          </IconButton>
                        </Tooltip>
                      </Styled.WrapperActionButtons>
                    </Styled.WrapperLink>
                  </TableCell>
                  <Styled.TableCell $isInactive={isActive}>
                    {booleanToString(isActive, 'Ativo', 'Inativo')}
                  </Styled.TableCell>
                  <TableCell>
                    {OPTIONS_TYPE.find((i) => i.value === type)?.label || '---'}
                  </TableCell>
                  <TableCell>
                    {booleanToString(
                      isRequired,
                      'Obrigatório',
                      'Não obrigatório'
                    )}
                  </TableCell>
                  <TableCell>
                    {Array.isArray(extension)
                      ? extension.join(', ')
                      : extension}
                  </TableCell>
                </Styled.TableRow>
              )
            )}
          </TableBody>
        </Table>

        {count > DEFAULT_ITEMS_PER_PAGE && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={currentPage}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setPage(DEFAULT_PAGE)
              setFilter(e.target.value)
            }}
          />
        )}
      </Paper>
      <Styled.Legend>
        <PlusOne /> Pode ser enviado mais de uma vez
      </Styled.Legend>

      <ModalConfirm
        iconWarningAmber
        message="Você tem certeza que deseja inativar este documento?"
        onClickConfirm={() => {
          handleUpdateDocumentStatus({
            id: selectedId,
            isActive: false,
            setModal: setOpenModalInactiveDocumentType
          })
        }}
        onClickNotConfirm={() => {
          setSelectedId(null)
          setOpenModalInactiveDocumentType(!openModalInactiveDocumentType)
        }}
        open={openModalInactiveDocumentType}
        textButtonConfirm="Sim"
        textButtonNotConfirm="Não"
        title="Inativar Tipo de Documento"
      />

      <ModalConfirm
        iconWarningAmber
        message="Você tem certeza que deseja ativar este documento?"
        onClickConfirm={() => {
          handleUpdateDocumentStatus({
            id: selectedId,
            isActive: true,
            setModal: setOpenModalActiveDocumentType
          })
        }}
        onClickNotConfirm={() => {
          setSelectedId(null)
          setOpenModalActiveDocumentType(!openModalActiveDocumentType)
        }}
        open={openModalActiveDocumentType}
        textButtonConfirm="Sim"
        textButtonNotConfirm="Não"
        title="Ativar Tipo de Documento"
      />
    </Styled.Wrapper>
  )
}
export { List }
List.propTypes = {
  handleSetModalContent: PropTypes.func
}

List.defaultProps = {
  handleSetModalContent: () => {}
}
