import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { getRgba } from 'services/helpers/polished'
import { Typography } from '@mui/material'

const LinkBreadcrumbs = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => getRgba(theme.palette.dark.main, 0.38)};
  & b {
    color: ${({ theme }) => getRgba(theme.palette.dark.main, 0.87)};
  }
`
const NotLinkBreadcrumbs = styled(Typography)`
  text-decoration: none;
  color: ${({ theme }) => getRgba(theme.palette.dark.main, 0.38)};
  & b {
    color: ${({ theme }) => getRgba(theme.palette.dark.main, 0.87)};
  }
`

const Box = styled.div`
  text-decoration: none;
  color: ${({ theme }) => getRgba(theme.palette.dark.main, 0.38)};
  & b {
    color: ${({ theme }) => getRgba(theme.palette.dark.main, 0.87)};
  }
`

export { LinkBreadcrumbs, Box, NotLinkBreadcrumbs }
